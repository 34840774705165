import React, { useMemo } from 'react'
import {
  Stack,
  Box,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  // DrawerCloseButton,
} from '@chakra-ui/react'
import { Link } from 'react-scroll'
import { MdClose } from "react-icons/md";
import Button from 'components/Button'

import { inspirations } from './HomePage'

const Menu = ({ isOpen, onClose, opacity, isAu, ...props }) => {
  const sections = useMemo(() => inspirations.slice(0, isAu ? 6 : 4) ,[isAu])
  return (
    <Drawer
      // transform={isOpen ? 'none' : 'translateX(100%)'}
      // position="fixed"
      // top="0"
      // right={0}
      // transition="transform 250ms"
      // height="100vh"
      // width={'100vw'}
      // zIndex={40}
      // textAlign="right"
      // opacity={opacity}
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      {...props}
    >
      {/* <Box left="0" right="0" bottom={0} top={0} bg={"rgba(0,0,0,0.5)"} opacity={isOpen ? 1 : 0} position={'absolute'} /> */}
      <DrawerOverlay />
      <DrawerContent px="0.5rem" pt="2.875em" pb="0.875em" bg="menuBg" display={'inline-block'} style={{ width: 'auto' }}>
        <IconButton
          pos="absolute"
          right="0.5rem"
          top="1rem"
          bg="transparent"
          color="black"
          minW="auto"
          onClick={onClose}
          _active={{
            bg: 'transparent'
          }}
          _hover={{
            bg: 'transparent'
          }}
          icon={<MdClose size="1.5em" />}
        />
        <Stack>
          {sections.map(({ name, key }, i) => (
            <Box pt="0.625em" borderTop={i && '1px solid white'} key={key}>
              {/* <Button
                width="100%"
                as={Link}
                to={key}
                smooth
                spy
                hashSpy
                delay={500}
                p="0"
                offset={key === 'reference' ? -120 : -70}
                variant={'transparent'}
                color="black"
                fontSize="0.75em"
                borderRadius="0"
                onClick={() => console.log(key)}
                justifyContent="flex-end"
                key={key}
              >{name}</Button> */}
              <Button
                width="100%"
                to={isAu ? `/au/#${key}` : `/#${key}`}
                p="0"
                variant={'transparent'}
                color="black"
                fontSize="0.75em"
                borderRadius="0"
                onClick={onClose}
                justifyContent="flex-end"
              >{name}</Button>
            </Box>
          ))}
        </Stack>
      </DrawerContent>
    </Drawer>
  )
}

export default Menu
