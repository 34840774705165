import { HStack } from '@chakra-ui/react';
import React, { useEffect,useMemo,useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import range from 'lodash/range';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';

import Box from './Box';
import DataBlock from './DataBlock';
import Button from './Button';

const ArrowButton = ({ onClick, ...props }) => {
  return (
    <Button
      onClick={onClick}
      minWidth={'auto'}
      width="0.75rem"
      height="0.75rem"
      minH="auto"
      p="0"
      bg="transparent"
      _hover={{ bg: 'transparent' }}
      {...props}
    />
  )
}

const StyledSwiper = styled(Swiper)`
  .swiper-slide.swiper-slide-prev {
    transform: ${props => !props.length && 'translateX(40%)'};
  }
  .swiper-slide.swiper-slide-active {
    transition: all 500ms;
    transform-origin: center;
    transform-delay: 250ms;
    transform: ${props => !props.isShort && 'scale(1.2)'};
    z-index: 5
  }
  .swiper-slide.swiper-slide-next {
    transform: ${props => !props.isShort && 'translateX(-40%)'};
  }
`

const DataSwiper = ({ port }) => {
  const [activeIndex, setIndex] = useState(0)
  const ref = useRef()
  useEffect(() => {
    if(!ref.current) return null
  }, [ref])
  const isShort = useMemo(() => port.web.length < 3 ,[port.web.length])
  return (
    <Box pos="relative">
      <StyledSwiper
        // loop={true}
        spaceBetween={isShort ? 32 : 10}
        slidesPerView={isShort ? port.web.length : 1}
        isShort={isShort}
        onSlideChange={(swiper) => setIndex(swiper.activeIndex)}
        ref={ref}
        style={{ overflow: 'visible' }}
      >
        {port.web.map((d, key) => (
          <SwiperSlide key={key}>
            <DataBlock d={d} port={port} isShort={isShort} index={key}/>
          </SwiperSlide>
        ))}
      </StyledSwiper>
      {!isShort && (
        <Box width="100%" pos="absolute" left={'50%'} bottom={"-1em"} transform="translate(-50%, 100%)" zIndex={5}>
          <HStack justifyContent={'center'} alignItems="center" spacing={3}>
            <ArrowButton
              disabled={!activeIndex}
              onClick={() => {
                ref?.current?.swiper.slidePrev()
                setIndex(activeIndex - 1)
              }}
            >
              <StaticImage src="./arrow_prev.png" alt="arrow-prev" />
            </ArrowButton>
            {range(port.web.length).map((num) => (
              <Button
                onClick={() => {
                    ref?.current?.swiper.slideTo(num)
                    setIndex(num)
                  }
                }
                width="0.375rem"
                height="0.375rem"
                p="0"
                minW={'auto'}
                isRound
                bg={num === (activeIndex % port.web.length) ? 'white' : "dotGray"}
                _hover={{ bg: 'white' }}
                _active={{ bg: 'white' }}
                key={num}
              />
            ))}
            <ArrowButton
              disabled={activeIndex === (port.web.length - 1)}
              onClick={() => {
                ref?.current?.swiper.slideNext()
                setIndex(activeIndex + 1)
              }}
            >
              <StaticImage src="./arrow_next.png" alt="arrow-next" />
            </ArrowButton>
          </HStack>
        </Box>
      )}
    </Box>
  )
}

export default DataSwiper
