import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { responsive } from 'contexts/responsive'

import Link from 'components/Link'
import WebReferModule from 'components/WebReferModule'
import Section from 'components/Section'

import PinBoardCover from './PinBoardCover'
import Button from 'components/Button'

const pins = [
  {
    id: "557602066303721938",
    en: 'Website Design / UI',
    zh: 'Design互動式網頁/測驗',
    trackerName: 'reference_pin_website',
    href: 'https://www.pinterest.com/relab/formwebsite-design-ui-design-%E4%BA%92%E5%8B%95%E5%BC%8F%E7%B6%B2%E9%A0%81%E6%B8%AC%E9%A9%97/'
  },
  {
    id: "557602066303721941",
    en: 'Social Media Post',
    zh: '社群貼文資訊圖表',
    trackerName: 'reference_pin_social',
    href: 'https://www.pinterest.com/relab/formsocial-media-post-%E7%A4%BE%E7%BE%A4%E8%B2%BC%E6%96%87%E8%B3%87%E8%A8%8A%E5%9C%96%E8%A1%A8/'
  },
  {
    id: "557602066303721943",
    en: 'Report Design',
    zh: '手冊/成果報告',
    trackerName: 'reference_pin_report',
    href: 'https://www.pinterest.com/relab/formreport-design-%E6%89%8B%E5%86%8A%E6%88%90%E6%9E%9C%E5%A0%B1%E5%91%8A/'
  },
  {
    id: "557602066303721940",
    en: 'Infographic Poster / Flyer',
    zh: '實體印刷資訊圖表',
    trackerName: 'reference_pin_poster',
    href: 'https://www.pinterest.com/relab/infographic%E8%B3%87%E8%A8%8A%E5%9C%96%E8%A1%A8/'
  },
  {
    id: "557602066303721936",
    en: 'Exhibition Infographic',
    zh: '展場中的資訊圖表',
    trackerName: 'reference_pin_exhibition',
    href: 'https://www.pinterest.com/relab/formexhibition-infographic-%E5%B1%95%E5%A0%B4%E4%B8%AD%E7%9A%84%E8%B3%87%E8%A8%8A%E5%9C%96%E8%A1%A8/'
  },
  {
    id: "557602066303721939",
    en: 'Animation Infographic',
    zh: '說明動畫',
    trackerName: 'reference_pin_animation',
    href: 'https://www.pinterest.com/relab/formanimation-infographic-%E8%AA%AA%E6%98%8E%E5%8B%95%E7%95%AB/'
  },
  {
    id: "557602066303721944",
    en: 'Icon Design',
    zh: 'Icon設計',
    trackerName: 'reference_pin_icon',
    href: 'https://www.pinterest.com/relab/formicon-design-icon%E8%A8%AD%E8%A8%88/'
  },
  {
    id: "557602066303725714",
    en: 'Infographic',
    zh: '實體圖表',
    trackerName: 'reference_pin_infographic',
    href: 'https://www.pinterest.com/relab/infographic-%E5%AF%A6%E9%AB%94%E5%9C%96%E8%A1%A8/'
  },
  {
    id: "557602066303721945",
    en: 'Data visualization',
    zh: '資料視覺化',
    trackerName: 'reference_pin_datavisualization',
    href: 'https://www.pinterest.com/relab/data-visualization%E8%B3%87%E6%96%99%E8%A6%96%E8%A6%BA%E5%8C%96/'
  },
]

const portfolio = [
  {
    title: '視覺化專家精選的資訊設計好作品',
    text: '透過專家的眼光，看作品同時也看評論！',
    web: [
      { name: 'Malofiej', desc: '新聞資訊圖表獎項，得獎作品有平面也有互動形式，適合參考專題的互動手法與切入點', tags: ['資料新聞', '專題作品', '作品資料庫'], href: 'https://iguacel.github.io/malofiej/', trackerName: 'reference_master_malofiej', },
      { name: 'The SIGMA Awards', desc: '資料新聞比賽獎項，得獎作品較偏重數據圖像化圖表的新聞專題', tags: ['資料新聞' ,'專題作品', '歷年作品資料庫'], href: 'https://sigmaawards.org', trackerName: 'reference_master_thesigmaawards', },
      { name: 'Information is Beautiful', desc: '知名的資訊設計獎項，得獎作品有不同主題不同形式，但共同的特色就是非常美', tags: ['獎項' ,'作品類型多元', '歷年作品資料庫'], href: 'https://www.informationisbeautifulawards.com', trackerName: 'reference_master_informationisbeautiful', },
      { name: 'Flowing Data', desc: '每天Nathan Yau會挑選一則圖表，除了看作品，也可以從他的評論學到解讀圖表的方式', tags: ['作品類型多元' ,'評論', '每日更新'], href: 'https://flowingdata.com/', trackerName: 'reference_master_flowingdata', },
      { name: 'DataWrapper', desc: '每週挑選3-4個主題的資訊圖表，可以看到同一個時事主題不同切入點或呈現方式的視覺化圖表', tags: ['新聞圖表' ,'每週更新'], href: 'https://blog.datawrapper.de/category/data-vis-dispatch/', trackerName: 'reference_master_datawrapper', },
      { name: 'Visualising data', desc: '每月Andy Kirk會精選特別的視覺化作品，另外網站上也整理了相當完整的製作資源', tags: ['專題作品' ,'每月更新', '製作資源'], href: 'https://www.visualisingdata.com/blog/', trackerName: 'reference_master_visualisingdata', },
    ]
  },
  {
    title: '頂尖資訊設計團隊的好作品',
    text: '從厲害的作品，看別人如何解決不同的設計難題！',
    web: [
      { trackerName: 'reference_team_infografika', name: 'Infografika', desc: '作品的完整度高，不管是插圖風格或資訊圖像化的呈現都很適合參考', tags: ['風格多元', '互動網站', '資訊圖像化'], href: 'https://www.infografika.agency' },
      { trackerName: 'reference_team_tabletopwhale', name: 'Tabletopwhale', desc: '擅長於科學地理類型知識，並將其資訊圖像化，圖像風格細節多', tags: ['生命科學' ,'醫學', '宇宙'], href: 'https://tabletopwhale.com/index.html' },
      { trackerName: 'reference_team_designpurple', name: 'Designpurple', desc: '韓國設計公司，作品類型多為刊物設計，可作成果報告資訊圖像化參考', tags: ['刊物' ,'編排', '成果報告'], href: 'https://designpurple.co.kr/PORTFOLIO' },
      { trackerName: 'reference_team_pentagram', name: 'Pentagram', desc: '有提供資訊圖像化服務的設計工作室，同時有其他製作物類型作品可參考', tags: ['作品類型多元' ,'數據圖像化', '風格簡約'], href: 'hhttps://www.pentagram.com' },
      { trackerName: 'reference_team_5wgraphics', name: '5WGraphics', desc: '擅長於數據視覺化設計公司，設計作品涵蓋世界各地，風格較於理性', tags: ['數據圖像化' ,'風格簡約'], href: 'http://www.5wgraphics.com/en/gallery.php' },
      { trackerName: 'reference_team_thepudding', name: 'The Pudding', desc: '作品非常具實驗性、但同時內容與互動的完成度都相當高，特別推薦他們軟性切入議題的方式', tags: ['互動網站', '風格多元'], href: 'https://pudding.cool/' },
    ]
  },
  {
    title: '來自世界各地的設計作品平台',
    text: '推薦給你我們常搜的關鍵字，你可以找到來自全世界的最新作品！',
    recommend: true,
    web: [
      { trackerName: 'reference_platform_behance', name: 'Behance', desc: '涵蓋全世界的作品集網站，作品呈現清楚，能深入了解作品理念', tags: ['Data Visualization ', 'infographic', 'information design'], href: 'https://www.behance.net' },
      { trackerName: 'reference_platform_huaban', name: '花瓣網', desc: '中國作品集網站，作品多為中國設計，可參考部分中文的編排形式', tags: ['信息圖表' ,'資訊視覺化'], href: 'https://huaban.com' },
      { trackerName: 'reference_platform_notefolio', name: 'notefolio', desc: '韓國作品集網站，作品多以插圖結合資訊，適合作為懶人包呈現的參考', tags: ['인포그래픽' ,'데이터 시각화', '인포메이션'], href: 'https://notefolio.net' },
    ]
  },
]

const Reference = () => {
  return (
    <Section
      title={'從參考作品找出呈現靈感'}
      desc={'已經有資訊，對於設計的包裝卻沒有靈感嗎？\n從我們精選的參考作品或是著名的資訊設計作品網站，來進行一場腦力的激盪吧！'}
      noborder
      pb={responsive('0', '7em')}
    >
      <Flex flexDirection={responsive('column', 'row')} mt={responsive('1.25em', '4em')}>
        <Box flex={1} mr="3.125em">
          <Text fontWeight="bold" letterSpacing={responsive('0.1em', '0.2em')} fontSize={responsive('1em', '1.5em')}>RE:LAB團隊挑選的作品參考</Text>
          <Text mt={responsive('0.625rem', '1em')} fontSize={responsive('0.875em', '1em')} letterSpacing="0.08em">依照我們最常製作的幾種作品形式分類，每週持續更新中！</Text>
        </Box>
        <Flex flexWrap={'wrap'} mx="-1em" width={responsive('auto', '74.4%')}>
          {pins.map((d, i) => (
            <Box key={i} px="1em" width={responsive('50%', 1 / 3)} mt={responsive('1.125em', i / 3 >= 1 && '3.125em')}>
              <Button
                variant="link"
                href={d.href}
              >
                <Box width="100%" borderRadius={'1em'} overflow="hidden" bg="white" pos="relative">
                  <PinBoardCover id={d.id} />
                </Box>
                <Text mt={responsive('0.25rem', '0.5rem')} lineHeight={1.6} fontWeight="bold" letterSpacing="0.02em" fontSize={responsive('0.75em', '1.25em')}>
                  {d.en} <br/>
                  {d.zh}
                </Text>
              </Button>
            </Box>
          ))}
        </Flex>
      </Flex>
      <WebReferModule data={portfolio} />
    </Section>
  )
}

export default Reference
