import { useFirestoreCollectionData } from 'reactfire'
import useFirestore from "./useFirestore"

const useFirestoreInviPosts = (name, size = 20) => {
  const { collection, orderBy, query, firestore, limit, where } = useFirestore()
  const fbRef = collection(firestore, name)
  return useFirestoreCollectionData(query(fbRef, where("ts", "<", 1), orderBy('ts', 'desc')), { idField: 'id' })
}

export default useFirestoreInviPosts
