import React from 'react'
import { Center, Spinner } from '@chakra-ui/react'
import SocialSwiper from 'components/SocialSwiper'
import ensureFisrestore from 'contexts/firebase/ensureFisrestore'
import useFirestorePosts from 'hooks/useFirestorePosts'
import { Media } from 'contexts/responsive'

const IgPosts = (props) => {
  const { data } = useFirestorePosts('ig', 12)
  // console.log(data, 'ig')
  if (!data) return <Center><Spinner /></Center>
  return (
    <>
      <Media greaterThanOrEqual='md'>
        <SocialSwiper data={data} {...props} />
      </Media>
      <Media lessThan='md'>
        <SocialSwiper data={data} loop />
      </Media>
    </>
  )
}

export default ensureFisrestore(IgPosts)
