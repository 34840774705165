import { Box, Flex, Text } from '@chakra-ui/react'
import { responsive } from 'contexts/responsive'
import React, { useEffect, useMemo, useRef } from 'react'
import Button from './Button'

const DataBlock = ({ d, port, index, isShort }) => {
  const ref = useRef()
  useEffect(() => {
    if(!ref?.current) return null
  } , [ref?.current, ref?.current?.offsetWidth])
  const buttonFontSize = useMemo(() => (d.name.length) * 10 >= ref?.current?.offsetWidth ? `${ref?.current?.offsetWidth / (d.name.length / 2 + 2)}px` : responsive('1em', '1.25em') ,[ref?.current, ref?.current?.offsetWidth])
  return (
    <Box
      pos="relative"
      pb="0.625em"
      mx={responsive("auto", '0')}
      width={responsive(isShort ? '100%' : '50%', 1 / 3)}
      px={responsive(0, '1em')}
      mt={responsive('0', index / 3 >= 1 && (d.tags ? '5.625em' : '1.75em'))}
    >
      <Button trackerName={d.trackerName} ref={ref} width={"100%"} height={responsive('2.75rem', '3.375rem')} fontSize={buttonFontSize} href={d.href}>{d.name}</Button>
      <Box mt={responsive('1em', "1.25rem")} lineHeight={1.6} fontSize={responsive('0.75em', '1em')}>
        {d.desc}
      </Box>
      {d.tags && (
        <Flex mt={responsive('0.5rem', 0)} flexDirection={port.recommend ? 'column' : 'row'} position={responsive('static', 'absolute')} left="1em" right="1em" top="100%" flexWrap={'wrap'}>
          {port.recommend && <Text mb="0.125em" fontSize={responsive('0.75em', '1em')}>推薦搜尋關鍵字：</Text>}
          {d?.tags.map(tag => (
            <Text mr="0.5rem" color="tagColor" fontSize={responsive('0.75em', '0.875em')} key={tag}>#{tag}</Text>
          ))}
        </Flex>
      )}
    </Box>
  )
}

export default DataBlock
