import React from 'react'
import { Box, Flex, HStack, IconButton, Text, useDisclosure, useTheme } from '@chakra-ui/react';
import Link from 'components/Link';
import BackgroundImage from 'components/BackgroundImage';
import { AiOutlineMenu } from "react-icons/ai";
import { Media, responsive } from 'contexts/responsive';

import logo from './logo.png';
import Menu from './Menu';

const Header = ({ siteTitle, isAu, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  // console.log(isAu)
  return (
    <Flex
      as="header"
      position="fixed"
      top={0}
      left={0}
      right={0}
      alignItems="center"
      zIndex="docked"
      bg={isAu ? 'au' : 'black'}
      px="1.625em"
      height={responsive('3rem', 'header')}
      justifyContent={responsive('center', 'flex-start')}
      // height={responsive('auto', '65px')}
      {...props}
    >
      <HStack alignItems={'center'}>
        <Link to="/" color="white">
          <Box width={responsive('3.25em', '6.875em')}><BackgroundImage src={logo} ratio={110 / 21} /></Box>
        </Link>
        <Text fontWeight={500} color="white" fontSize={responsive('0.75em', '1.5em')}>資訊設計靈感任意門</Text>
      </HStack>
      <Box as={Media} lessThan="md" pos="absolute" top="50%" right="1em" transform={'translateY(-50%)'}>
        <IconButton
          width="2em"
          height="2em"
          minW="auto"
          px="0"
          borderRadius={'5px'}
          border="0.5px solid white"
          bg="rgba(229, 229, 229, 0.3)"
          icon={<AiOutlineMenu size="1.25em" />}
          onClick={onOpen}
          _hover={{
            bg: "rgba(229, 229, 229, 0.3)"
          }}
          _active={{
            bg: "rgba(229, 229, 229, 0.3)"
          }}
        />
      </Box>
      <Menu isAu={isAu} isOpen={isOpen} onClose={onClose} />
      {/* <Menu opacity={0} zIndex={-1} /> */}
    </Flex>
  )
}

export default Header
