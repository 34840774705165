import React from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { responsive } from 'contexts/responsive'
import Section from 'components/Section'
import Card from 'components/Card'

const media = [
  {
    name: '報導者',
    text: '搭配不同的深度專題製作視覺化圖表，此外「開放實驗室」分享製作幕後也相當實用',
    tags: ['臺灣', '專題報導', '製作資源'],
    href: "https://www.twreporter.org/categories/infographic",
    trackerName: 'media_twreporter',
    bg: [{ color: '#B4292C' }],
  },
  {
    name: '日本經濟新聞',
    text: '日本主流媒體的圖表部門，因為使用和中文接近的日文，所以在圖表排版細節上相當值得參考，有付費牆',
    tags: ['日本', '時事', '專題報導'],
    href: "https://vdata.nikkei.com/",
    trackerName: 'media_nikkei',
    bg: [{ color: '#173D6C' }],
  },
  {
    name: 'SCMP 南華早報',
    text: '深度經營中港澳及亞洲時事的資料新聞媒體，在圖表中大量應用插畫解釋是一大特色，有付費牆',
    tags: ['香港', '政治', '專文'],
    href: "https://www.scmp.com/infographic/",
    trackerName: 'media_SCMP',
    bg: [{ color: '#F7CC46', width: '50%' }, { color: '#041243', width: '50%' }, ],
  },
  {
    name: 'New York Times',
    text: '全球數一數二的資料新聞團隊，專題的互動手法與圖表細節都相當值得學習，有付費牆',
    tags: ['美國', '國際時事', '專題報導'],
    href: "https://www.nytimes.com/spotlight/graphics",
    trackerName: 'media_newyorktimes',
    bg: [
      { color: '#A0AD88', width: '22.4%' }, { color: '#C75437', width: '8.8%' },
      { color: '#DDB9B3', width: '36%' }, { color: '#C1CDB1', width: '32.8%' },
    ],
  },
]

const MediaSection = () => {
  return (
    <Section
      title="從媒體平台找內容行銷靈感"
      desc={'想為品牌發展主題式的內容，卻不知從何開始嗎？\n從這些搭配資訊圖表呈現的專題報導，找到適合你的脈絡與方向！'}
    >
      <Box mx="auto" maxW={responsive('auto', '75%', '60%', '100%')} mt={responsive('0', '2em')} >
        <Flex flexWrap={'wrap'} justifyContent={'center'} mx="-1em">
          {media.map((d, i) => (
            <Card d={d} key={i}/>
          ))}
        </Flex>
      </Box>
    </Section>
  )
}

export default MediaSection
