import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Stack,
  Table,
  Td,
  Text,
  Th,
  Tr,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from '@chakra-ui/react'
import range from 'lodash/range'
import chunk from 'lodash/chunk'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IoIosArrowDown } from "react-icons/io";
import getMonth from 'date-fns/getMonth'

import Link from 'components/Link'
import Section from 'components/Section'
import { Media, responsive } from 'contexts/responsive'
import sortBy from 'lodash/sortBy'
import last from 'lodash/last'
import ReportModal from 'components/ReportModal'

const monthData = [
  {
    mon: 'Jan',
    data: [
      {
        report: "World Employment and Social Outlook: Trends",
        href: "https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/---publ/documents/publication/wcms_734455.pdf",
        org: "國際勞工組織(ILO)",
        issue: ["勞動就業"]
      },
      {
        report: "國際數學與科學教育成就趨勢調查(TIMSS 2019)",
        href: "https://timss2019.org/reports/",
        org: "教育成就評鑑國際協會(IEA)",
        issue: ["基本學力"]
      },
      {
        report: "國內總體經濟預測暨景氣動向調查",
        href: "https://www.tier.org.tw/forecast/macro_trends_annual.aspx",
        org: "台經院",
        issue: ["經濟成長"]
      },
      {
        report: "國際農工原料價格漲跌概況",
        href: "https://www.stat.gov.tw/lp.asp?CtNode=1495&CtUnit=690&BaseDSD=7&xq_xCat=11",
        org: "主計總處",
        issue: ["市場動向"]
      },
      {
        report: "全球貪腐報告(Corruption Perceptions Index)",
        href: "https://www.transparency.org/en/cpi/2019",
        org: "國際透明組織",
        issue: ["貪污賄選"]
      },
      {
        report: "器官捐贈人數統計",
        href: "https://www.torsc.org.tw/docList.jsp?uid=158&pid=9&rn=246736701",
        org: "財團法人器官捐贈移植登錄中心",
        issue: ["器官捐贈"]
      },
      {
        report: "流行音樂產業調查報告",
        href: "https://www.bamid.gov.tw/downloadfilelist_245.html",
        org: "文化部",
        issue: ["音樂"]
      },
    ]
  },
  {
    mon: 'Feb',
    data: [
      {
        report: "全國及六都住宅價格指數",
        href: "https://pip.moi.gov.tw/V3/E/SCRE0106.aspx",
        org: "內政部",
        issue: ["房價負擔"]
      },
      {
        report: "房價負擔能力統計",
        href: "https://pip.moi.gov.tw/V3/E/SCRE0105.aspx",
        org: "內政部",
        issue: ["房價負擔"]
      },
      {
        report: "國民所得統計-經濟成長率概估統計",
        href: "https://www.stat.gov.tw/np.asp?ctNode=492",
        org: "主計總處",
        issue: ["經濟成長"]
      },
      {
        report: "臺灣圖書出版現況及趨勢報告",
        href: "http://isbn.ncl.edu.tw/NEW_ISBNNet/main_ProcessLevel3.php?Ptarget=253",
        org: "國家圖書館",
        issue: ["圖書借閱"]
      },
      {
        report: "學科能力測驗統計圖表",
        href: "https://www.ceec.edu.tw/xmdoc/cont?xsmsid=0J018604485538810196&sid=0J100599167817531498",
        org: "大考中心",
        issue: ["基本學力"]
      },
      {
        report: "金融統計-重要金融指標",
        href: "https://www.cbc.gov.tw/tw/lp-523-1.html",
        org: "金管會",
        issue: ["市場動向"]
      },
      {
        report: "信用卡業務統計",
        href: "https://www.banking.gov.tw/ch/home.jsp?id=157&parentpath=0,4&mcustomize=bstatistics_view.jsp&serno=201105120008",
        org: "金管會",
        issue: ["民間消費"]
      },
      {
        report: "勞資爭議處理統計",
        href: "https://www1.stat.gov.tw/ct.asp?xItem=15418&CtNode=4701&mp=3",
        org: "勞動部",
        issue: ["勞資爭議"]
      },
      {
        report: "臺灣閱讀風貌及全民閱讀力年度報告",
        href: "http://isbn.ncl.edu.tw/NEW_ISBNNet/main_ProcessLevel3.php?Ptarget=253",
        org: "國家圖書館",
        issue: ["圖書借閱"]
      },
      {
        report: "大專校院境外學生在臺留學/研習人數",
        href: "https://depart.moe.edu.tw/ED2500/News_Content.aspx?n=E629CC6F403F7402&sms=F42C4CEA6ED95269&s=1DFFB3B78ADF0E40",
        org: "教育部",
        issue: ["國際學生"]
      },
      {
        report: "電力事業尖峰負載、備用容量率",
        href: "https://www.taipower.com.tw/tc/page.aspx?mid=43&cid=27&cchk=519b2345-d73a-40ba-81d9-2f7e55f51b2f",
        org: "經濟部",
        issue: ["用電"]
      },
    ]
  },
  {
    mon: 'Mar',
    data: [
      {
        report: "World Happiness Report",
        href: "https://worldhappiness.report/",
        org: "聯合國可持續發展解決方案網絡倡議(SDSN)",
        issue: ["生活品質"]
      },
      {
        report: "台灣央行利率決議",
        href: "https://www.cbc.gov.tw/tw/sp-news-list-1.html",
        org: "中央銀行",
        issue: ["利率穩定"]
      },
      {
        report: "重要河川污染現況",
        href: "https://stat.epa.gov.tw/",
        org: "環保署",
        issue: ["河川污染"]
      },
      {
        report: "補助藝文團體或個人展演活動統計",
        href: "https://www.moc.gov.tw/informationlist_286.html",
        org: "文化部",
        issue: ["藝文補助"]
      },
      {
        report: "藝文展演活動統計",
        href: "https://www.moc.gov.tw/informationlist_286.html",
        org: "文化部",
        issue: ["藝文活動"]
      },
      {
        report: "垃圾性質分析",
        href: "https://stat.epa.gov.tw/",
        org: "環保署",
        issue: ["垃圾回收"]
      },
      {
        report: "水資源供需統計",
        href: "https://www.wra.gov.tw/News_Content.aspx?n=2945&s=7394",
        org: "經濟部",
        issue: ["用水"]
      },
      {
        report: "數位機會調查報告",
        href: "https://www.ndc.gov.tw/cp.aspx?n=55c8164714dfd9e9",
        org: "國發會",
        issue: ["網路使用"]
      },
      {
        report: "自來水生活用水量統計",
        href: "https://www.wra.gov.tw/News_Content.aspx?n=2945&s=7414",
        org: "經濟部",
        issue: ["用水"]
      },
      {
        report: "保險市場重要指標",
        href: "https://www.ib.gov.tw/ch/home.jsp?id=48&parentpath=0,4",
        org: "金管會",
        issue: ["保險保障"]
      },
      {
        report: "藥物濫用案件暨檢驗統計資料",
        href: "https://antidrug.moj.gov.tw/cp-89-6792-2.html",
        org: "衛福部",
        issue: ["毒品藥癮"]
      },
      {
        report: "電業年報",
        href: "https://www.taipower.com.tw/tc/page.aspx?mid=43&cid=29&cchk=34db42ba-62b1-4684-9fc8-59881779ac23#b01",
        org: "台灣電力公司",
        issue: ["用電"]
      },
      {
        report: "IUCN Red List of Threatened Species",
        href: "https://www.iucnredlist.org/",
        org: "國際自然保護聯盟(IUCN)",
        issue: ["生物多樣"]
      },
    ]
  },
  {
    mon: 'Apr',
    data: [
      {
        report: "製造業投資及營運概況調查",
        href: "https://www.moea.gov.tw/MNS/dos/content/ContentLink.aspx?menu_id=9433",
        org: "經濟部",
        issue: ["企業獲利"]
      },
      {
        report: "臺灣經濟預測",
        href: "https://www.cier.edu.tw/TMF",
        org: "中華經濟研究院",
        issue: ["經濟成長"]
      },
      {
        report: "國內總體經濟預測暨景氣動向調查",
        href: "https://www.tier.org.tw/forecast/macro_trends_annual.aspx",
        org: "台灣經濟研究院",
        issue: ["經濟成長"]
      },
      {
        report: "國際農工原料價格漲跌概況",
        href: "https://www.stat.gov.tw/lp.asp?CtNode=1495&CtUnit=690&BaseDSD=7&xq_xCat=11",
        org: "主計總處",
        issue: ["市場動向"]
      },
      {
        report: "臺灣地區野生物種數統計",
        href: "https://agrstat.coa.gov.tw/sdweb/public/official/OfficialInformation.aspx",
        org: "農委會特生中心",
        issue: ["瀕危物種"]
      },
      {
        report: "國富統計",
        href: "https://www.stat.gov.tw/ct.asp?xItem=15451&CtNode=3658&mp=4",
        org: "主計總處",
        issue: ["國家資產"]
      },
      {
        report: "學生體育健康統計",
        href: "https://depart.moe.edu.tw/ED4500/cp.aspx?n=1B58E0B736635285&s=D04C74553DB60CAD",
        org: "教育部",
        issue: ["學童近視"]
      },
      {
        report: "全球創業觀察",
        href: "https://www.gemconsortium.org/report",
        org: "Global Entrepreneurship Monitor",
        issue: ["創新創業"]
      },
    ]
  },
  {
    mon: 'May',
    data: [
      {
        report: "警政統計年報",
        href: "https://www.npa.gov.tw/NPAGip/wSite/np?ctNode=12896&mp=1",
        org: "內政部",
        issue: ["公共安全"]
      },
      {
        report: "全國及六都住宅價格指數",
        href: "https://pip.moi.gov.tw/V3/E/SCRE0106.aspx",
        org: "內政部",
        issue: ["房價負擔"]
      },
      {
        report: "房價負擔能力統計",
        href: "https://pip.moi.gov.tw/V3/E/SCRE0105.aspx",
        org: "內政部",
        issue: ["房價負擔"]
      },
      {
        report: "國民所得統計-經濟成長率概估統計",
        href: "https://www.stat.gov.tw/np.asp?ctNode=492",
        org: "主計總處",
        issue: ["經濟成長"]
      },
      {
        report: "長期照顧十年計畫",
        href: "https://dep.mohw.gov.tw/DOS/lp-2966-113.html",
        org: "衛福部",
        issue: ["長期照顧"]
      },
      {
        report: "性侵害防治統計",
        href: "https://dep.mohw.gov.tw/dos/lp-2982-113.html",
        org: "衛福部",
        issue: ["性侵害"]
      },
      {
        report: "全國火災統計分析",
        href: "https://www.nfa.gov.tw/cht/index.php?code=list&ids=220",
        org: "內政部",
        issue: ["火災死亡"]
      },
      {
        report: "青年勞動統計",
        href: "https://www.mol.gov.tw/statistics/2462/2464/",
        org: "勞動部",
        issue: ["青年就業"]
      },
      {
        report: "人力資源統計-就業、失業統計",
        href: "https://www.stat.gov.tw/ct.asp?mp=4&xItem=42628&ctNode=4944",
        org: "主計總處",
        issue: ["就業狀況"]
      },
      {
        report: "電影市場動態年報",
        href: "https://www.bamid.gov.tw/informationlist_243.html",
        org: "文化部",
        issue: ["國片電影"]
      },
      {
        report: "職類別薪資調查",
        href: "https://pswst.mol.gov.tw/psdn/",
        org: "勞動部",
        issue: ["薪資"]
      },
    ]
  },
  {
    mon: 'Jun',
    data: [
      {
        report: "臺灣經濟預測",
        href: "https://www.cier.edu.tw/TMF",
        org: "中華經濟研究院",
        issue: ["經濟成長"]
      },
      {
        report: "低度及待售住宅統計",
        href: "https://pip.moi.gov.tw/V3/E/SCRE0104.aspx",
        org: "內政部",
        issue: ["空屋"]
      },
      {
        report: "台灣能源安全指標",
        href: "http://rcted.ncu.edu.tw/cci/1090629%20%E8%87%BA%E7%81%A3%E8%83%BD%E6%BA%90%E5%AE%89%E5%85%A8%E6%8C%87%E6%A8%99v2.pdf",
        org: "中央大學台灣經濟發展研究中心",
        issue: ["能源安全"]
      },
      {
        report: "全球再生能源現況報告",
        href: "https://www.ren21.net/reports/ren21-reports/",
        org: "21世紀再生能政策網絡研究機構(REN21)",
        issue: ["再生能源"]
      },
      {
        report: "美國人口販運問題報告",
        href: "https://www.ait.org.tw/zhtw/tag/trafficking-in-persons-report-zhtw/",
        org: "美國在台協會",
        issue: ["人口販運"]
      },
      {
        report: "家庭暴力防治統計",
        href: "https://dep.mohw.gov.tw/dops/lp-1303-105-xCat-cat01.html",
        org: "衛福部",
        issue: ["家庭暴力"]
      },
      {
        report: "綜合所得稅申報初步核定統計專冊",
        href: "https://www.fia.gov.tw/multiplehtml/43",
        org: "財政部",
        issue: ["貧富差距"]
      },
      {
        report: "癌症登記報告",
        href: "https://www.hpa.gov.tw/Pages/List.aspx?nodeid=119",
        org: "衛福部",
        issue: ["癌症"]
      },
      {
        report: "死因統計",
        href: "https://dep.mohw.gov.tw/dos/np-1776-113.html",
        org: "衛福部",
        issue: ["癌症"]
      },
      {
        report: "死因統計",
        href: "https://dep.mohw.gov.tw/dos/np-1776-113.html",
        org: "衛福部",
        issue: ["自殺死亡"]
      },
      {
        report: "國人吸菸行為調查",
        href: "https://olap.hpa.gov.tw//search/ListHealth1.aspx?menu=1&mode=12&year=107",
        org: "衛福部",
        issue: ["吸菸"]
      },
      {
        report: "IRODaT Newsletter",
        href: "https://www.irodat.org/?p=publications",
        org: "國際器官捐贈與移植登記組織(IRODaT)",
        issue: ["器官捐贈"]
      },
      {
        report: "全國自殺死亡資料統計",
        href: "https://dep.mohw.gov.tw/domhaoh/fp-4904-8883-107.html",
        org: "全國自殺防治中心",
        issue: ["自殺死亡"]
      },
      {
        report: "各級政府歲入、歲出、餘絀及融資調度",
        href: "http://web02.mof.gov.tw/njswww/webMain.aspx?sys=210&kind=21&type=1&funid=i0101",
        org: "財政部",
        issue: ["政府債務"]
      },
      {
        report: "外銷訂單海外生產實況調查統計",
        href: "https://www.moea.gov.tw/Mns/dos/content/ContentLink.aspx?menu_id=9612",
        org: "經濟部",
        issue: ["海外代工"]
      },
      {
        report: "原住民就業狀況調查統計",
        href: "https://www.cip.gov.tw/portal/docList.html?currentPage=1&CID=19F6DD25969C101D",
        org: "原民會",
        issue: ["原民權益"]
      },
      {
        report: "勞工保險職業災害保險給付人次及千人率",
        href: "https://statdb.mol.gov.tw/html/mon/26080.pdf",
        org: "勞動部",
        issue: ["職業災害"]
      },
      {
        report: "World Migration Report",
        href: "https://www.iom.int/wmr/",
        org: "國際移民組織(IOM)",
        issue: ["移民遷徙"]
      },
    ]
  },
  {
    mon: 'Jul',
    data: [
      {
        report: "國內總體經濟預測暨景氣動向調查",
        href: "https://www.tier.org.tw/forecast/macro_trends_annual.aspx",
        org: "台經院",
        issue: ["經濟成長"]
      },
      {
        report: "國際農工原料價格漲跌概況",
        href: "https://www.stat.gov.tw/lp.asp?CtNode=1495&CtUnit=690&BaseDSD=7&xq_xCat=11",
        org: "主計總處",
        issue: ["市場動向"]
      },
      {
        report: "健康促進統計年報",
        href: "https://www.hpa.gov.tw/Pages/List.aspx?nodeid=118",
        org: "衛福部",
        issue: ["過重肥胖"]
      },
      {
        report: "臺灣經濟情勢總展望之修正",
        href: "http://www.econ.sinica.edu.tw/content/downloads/list/2013090215150494922/",
        org: "中研院",
        issue: ["經濟成長"]
      },
      {
        report: "中高齡及高齡勞動統計",
        href: "https://www.mol.gov.tw/statistics/2462/2466/",
        org: "勞動部",
        issue: ["高齡就業"]
      },
      {
        report: "Global Multidimensional Poverty Index",
        href: "http://hdr.undp.org/en/2020-MPI",
        org: "聯合國開發計劃署(UNDP)",
        issue: ["貧窮人口"]
      },
    ]
  },
  {
    mon: 'Aug',
    data: [
      {
        report: "全國及六都住宅價格指數",
        href: "https://pip.moi.gov.tw/V3/E/SCRE0106.aspx",
        org: "內政部",
        issue: ["房價負擔"]
      },
      {
        report: "房價負擔能力統計",
        href: "https://pip.moi.gov.tw/V3/E/SCRE0105.aspx",
        org: "內政部",
        issue: ["房價負擔"]
      },
      {
        report: "國民所得統計-經濟成長率概估統計",
        href: "https://www.stat.gov.tw/np.asp?ctNode=492",
        org: "主計總處",
        issue: ["經濟成長"]
      },
      {
        report: "法務統計年報",
        href: "https://www.rjsd.moj.gov.tw/rjsdweb/book/Book.aspx?category_id=4",
        org: "法務部",
        issue: ["再犯前科"]
      },
      {
        report: "法務統計年報",
        href: "https://www.rjsd.moj.gov.tw/rjsdweb/book/Book.aspx?category_id=4",
        org: "法務部",
        issue: ["監獄矯正"]
      },
      {
        report: "營利事業所得稅結算申報核定統計專冊",
        href: "https://www.mof.gov.tw/singlehtml/285?cntId=80294",
        org: "財政部",
        issue: ["政府財政"]
      },
      {
        report: "中央政府總預算歲入、歲出、餘絀、融資",
        href: "https://win.dgbas.gov.tw/dgbas03/bs7/calendar/MetaQry.asp?QM=0&MetaId=164&fyear=2021&fmonth=1",
        org: "主計總處",
        issue: ["政府財政"]
      },
      {
        report: "家庭收支調查",
        href: "https://win.dgbas.gov.tw/dgbas03/bs7/calendar/MetaQry.asp?QM=0&MetaId=159&fyear=2021&fmonth=1",
        org: "主計總處",
        issue: ["住宅支出"]
      },
      {
        report: "家庭收支調查",
        href: "https://win.dgbas.gov.tw/dgbas03/bs7/calendar/MetaQry.asp?QM=0&MetaId=159&fyear=2021&fmonth=1",
        org: "主計總處",
        issue: ["家庭組成"]
      },
      {
        report: "家庭收支調查",
        href: "https://win.dgbas.gov.tw/dgbas03/bs7/calendar/MetaQry.asp?QM=0&MetaId=159&fyear=2021&fmonth=1",
        org: "主計總處",
        issue: ["貧富差距"]
      },
      {
        report: "人口推估報告",
        href: "https://pop-proj.ndc.gov.tw/index.aspx",
        org: "國發會",
        issue: ["人口高齡"]
      },
      {
        report: "人口推估報告",
        href: "https://pop-proj.ndc.gov.tw/index.aspx",
        org: "國發會",
        issue: ["少子女化"]
      },
      {
        report: "來臺旅客消費及動向調查報告",
        href: "https://admin.taiwan.net.tw/FileUploadCategoryListC003340.aspx?CategoryID=6f2f646b-872e-44f1-b725-a75c0f7d0bf9&appname=FileUploadCategoryListC003340",
        org: "交通部",
        issue: ["觀光旅遊"]
      },
      {
        report: "臺灣旅遊狀況調查報告",
        href: "https://admin.taiwan.net.tw/FileUploadCategoryListC003340.aspx?CategoryID=7b8dffa9-3b9c-4b18-bf05-0ab402789d59",
        org: "交通部",
        issue: ["觀光旅遊"]
      },
    ]
  },
  {
    mon: 'Sep',
    data: [
      {
        report: "製造業投資及營運概況調查",
        href: "https://www.moea.gov.tw/MNS/dos/content/ContentLink.aspx?menu_id=9433",
        org: "經濟部",
        issue: ["企業獲利"]
      },
      {
        report: "台灣央行利率決議",
        href: "https://www.cbc.gov.tw/tw/sp-news-list-1.html",
        org: "中央銀行",
        issue: ["市場動向"]
      },
      {
        report: "臺灣經濟預測",
        href: "https://www.cier.edu.tw/TMF",
        org: "中經院",
        issue: ["經濟成長"]
      },
      {
        report: "事業人力僱用狀況調查",
        href: "https://win.dgbas.gov.tw/dgbas03/bs7/calendar/MetaQry.asp?QM=0&MetaId=151&fyear=2021&fmonth=1",
        org: "主計總處",
        issue: ["勞動就業"]
      },
      {
        report: "國道事故檢討分析報告",
        href: "https://www.freeway.gov.tw/Publish.aspx?cnid=516&p=2849",
        org: "交通部",
        issue: ["交通事故"]
      },
      {
        report: "兒少保護統計",
        href: "https://dep.mohw.gov.tw/dos/lp-2985-113.html",
        org: "衛福部",
        issue: ["兒少受虐"]
      },
      {
        report: "國際失智症日衛生福利統計通報",
        href: "https://dep.mohw.gov.tw/dos/lp-4445-113.html",
        org: "衛福部",
        issue: ["精神疾病"]
      },
      {
        report: "糧食供需年報",
        href: "https://agrstat.coa.gov.tw/sdweb/public/book/Book.aspx",
        org: "農委會",
        issue: ["糧食自給"]
      },
    ]
  },
  {
    mon: 'Oct',
    data: [
      {
        report: "國內總體經濟預測暨景氣動向調查",
        href: "https://www.tier.org.tw/forecast/macro_trends_annual.aspx",
        org: "台經院",
        issue: ["經濟成長"]
      },
      {
        report: "國際農工原料價格漲跌概況",
        href: "https://www.stat.gov.tw/lp.asp?CtNode=1495&CtUnit=690&BaseDSD=7&xq_xCat=11",
        org: "主計總處",
        issue: ["市場動向"]
      },
      {
        report: "營建統計年報",
        href: "https://www.cpami.gov.tw/%E6%94%BF%E5%BA%9C%E8%B3%87%E8%A8%8A%E5%85%AC%E9%96%8B/%E4%B8%BB%E5%8B%95%E5%85%AC%E9%96%8B%E8%B3%87%E8%A8%8A/%E6%96%BD%E6%94%BF%E8%A8%88%E7%95%AB%E6%A5%AD%E5%8B%99%E7%B5%B1%E8%A8%88%E5%8F%8A%E7%A0%94%E7%A9%B6%E5%A0%B1%E5%91%8A/%E7%87%9F%E5%BB%BA%E7%B5%B1%E8%A8%88%E8%B3%87%E8%A8%8A/7302-%E7%87%9F%E5%BB%BA%E7%B5%B1%E8%A8%88%E5%B9%B4%E5%A0%B1.html",
        org: "內政部",
        issue: ["居住環境"]
      },
      {
        report: "World Economic Outlook Database",
        href: "https://www.imf.org/en/Publications/SPROLLs/world-economic-outlook-databases#sort=%40imfdate%20descending",
        org: "國際貨幣組織(IMF)",
        issue: ["經濟貿易"]
      },
      {
        report: "中華民國溫室氣體國家報告",
        href: "https://unfccc.saveoursky.org.tw/nir/tw_nir_2020.php",
        org: "環保署",
        issue: ["溫室氣體"]
      },
      {
        report: "機車使用狀況調查報告",
        href: "https://www.motc.gov.tw/ch/home.jsp?id=56&parentpath=0,6&mcustomize=statistics101.jsp",
        org: "交通部",
        issue: ["交通運輸"]
      },
      {
        report: "自用小客車使用狀況調查報告",
        href: "https://www.motc.gov.tw/ch/home.jsp?id=56&parentpath=0,6&mcustomize=statistics101.jsp",
        org: "交通部",
        issue: ["交通運輸"]
      },
      {
        report: "世界心理健康日衛生福利統計通報",
        href: "https://dep.mohw.gov.tw/dos/lp-4445-113.html",
        org: "衛福部",
        issue: ["精神疾病"]
      },
      {
        report: "國中小中途輟學學生人數",
        href: "http://stats.moe.gov.tw/files/time/out.htm",
        org: "教育部",
        issue: ["中途輟學"]
      },
      {
        report: "科技研發經費及人力指標",
        href: "https://wsts.most.gov.tw/stsweb/technology/TechnologyDataIndex.aspx?language=C",
        org: "科技部",
        issue: ["研發經費"]
      },
      {
        report: "批發、零售及餐飲業經營實況調查報告",
        href: "https://www.moea.gov.tw/MNS/dos/content/ContentLink.aspx?menu_id=9431",
        org: "經濟部",
        issue: ["電子商務"]
      },
      {
        report: "中小企業白皮書",
        href: "https://www.moeasmea.gov.tw/list-tw-2345",
        org: "經濟部",
        issue: ["中小企業"]
      },
      {
        report: "Road Safety Annual Report",
        href: "https://www.itf-oecd.org/road-safety-annual-report-2020",
        org: "國際運輸論壇(ITF)",
        issue: ["交通事故"]
      },
      {
        report: "Doing Business",
        href: "https://www.doingbusiness.org/",
        org: "世界銀行(WB)",
        issue: ["經商環境"]
      },
    ]
  },
  {
    mon: 'Nov',
    data: [
      {
        report: "全國及六都住宅價格指數",
        href: "https://pip.moi.gov.tw/V3/E/SCRE0106.aspx",
        org: "內政部",
        issue: ["房價負擔"]
      },
      {
        report: "房價負擔能力統計",
        href: "https://pip.moi.gov.tw/V3/E/SCRE0105.aspx",
        org: "內政部",
        issue: ["房價負擔"]
      },
      {
        report: "國民所得統計-經濟成長率概估統計",
        href: "https://www.stat.gov.tw/np.asp?ctNode=492",
        org: "主計總處",
        issue: ["經濟成長"]
      },
      {
        report: "Health at a Glance",
        href: "http://www.oecd.org/health/health-systems/health-at-a-glance-19991312.htm",
        org: "經濟合作暨發展組織(OECD)",
        issue: ["健康疾病"]
      },
      {
        report: "人力運用調查統計結果",
        href: "https://win.dgbas.gov.tw/dgbas03/bs7/calendar/MetaQry.asp?QM=0&MetaId=154&fyear=2021&fmonth=1",
        org: "主計總處",
        issue: ["勞動就業"]
      },
      {
        report: "全民健康保險統計",
        href: "https://dep.mohw.gov.tw/dos/np-2799-113.html",
        org: "衛福部",
        issue: ["醫療支出"]
      },
      {
        report: "歷年食品中毒資料",
        href: "https://www.fda.gov.tw/TC/siteContent.aspx?sid=323",
        org: "衛福部",
        issue: ["食品安全"]
      },
      {
        report: "天然災害統計",
        href: "https://www.nfa.gov.tw/cht/index.php?code=list&ids=233",
        org: "內政部",
        issue: ["天然災害"]
      },
      {
        report: "出生通報統計年報",
        href: "https://www.hpa.gov.tw/Pages/List.aspx?nodeid=55",
        org: "衛福部",
        issue: ["高齡產婦"]
      },
      {
        report: "Global Wage Report",
        href: "https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/---publ/documents/publication/wcms_762534.pdf",
        org: "國際勞工組織(ILO)",
        issue: ["薪資"]
      },
    ]
  },
  {
    mon: 'Dec',
    data: [
      {
        report: "製造業投資及營運概況調查",
        href: "https://www.moea.gov.tw/MNS/dos/content/ContentLink.aspx?menu_id=9433",
        org: "經濟部",
        issue: ["企業獲利"]
      },
      {
        report: "台灣央行利率決議",
        href: "https://www.cbc.gov.tw/tw/sp-news-list-1.html",
        org: "中央銀行",
        issue: ["利率穩定"]
      },
      {
        report: "The Global Gender Gap Report",
        href: "https://www.weforum.org/reports/gender-gap-2020-report-100-years-pay-equality",
        org: "世界經濟論壇(WEF)",
        issue: ["性別平等"]
      },
      {
        report: "臺灣經濟預測",
        href: "https://www.cier.edu.tw/TMF",
        org: "中經院",
        issue: ["經濟成長"]
      },
      {
        report: "低度及待售住宅統計",
        href: "https://pip.moi.gov.tw/V3/E/SCRE0104.aspx",
        org: "內政部",
        issue: ["空屋"]
      },
      {
        report: "台灣能源安全指標",
        href: "http://rcted.ncu.edu.tw/cci/1090629%20%E8%87%BA%E7%81%A3%E8%83%BD%E6%BA%90%E5%AE%89%E5%85%A8%E6%8C%87%E6%A8%99v2.pdf",
        org: "中央大學台灣經濟發展研究中心",
        issue: ["能源安全"]
      },
      {
        report: "永續發展指標系統評量結果報告",
        href: "https://nsdn.epa.gov.tw/%E5%B9%B4%E5%BA%A6%E4%B8%BB%E8%A6%81%E6%A5%AD%E5%8B%99/%E5%9C%8B%E5%AE%B6%E6%B0%B8%E7%BA%8C%E7%99%BC%E5%B1%95%E6%8C%87%E6%A8%99%E7%B3%BB%E7%B5%B1%E8%A9%95%E9%87%8F",
        org: "永續會",
        issue: ["自然環境"]
      },
      {
        report: "都市及區域發展統計彙編",
        href: "https://statistic.ndc.gov.tw/download.htm",
        org: "國發會",
        issue: ["居住環境"]
      },
      {
        report: "傳染病統計暨監視年報",
        href: "https://nidss.cdc.gov.tw/Home/Index?op=4",
        org: "衛福部",
        issue: ["傳染疾病"]
      },
      {
        report: "台灣網路報告",
        href: "https://twnic.tw/stat_n.php",
        org: "財團法人台灣網路資訊中心",
        issue: ["電子商務"]
      },
      {
        report: "台灣網路報告",
        href: "https://twnic.tw/stat_n.php",
        org: "財團法人台灣網路資訊中心",
        issue: ["數位機會"]
      },
      {
        report: "Human Development Report",
        href: "http://www.hdr.undp.org/",
        org: "聯合國開發計劃署(UNDP)",
        issue: ["居住環境"]
      },
      {
        report: "社會保障支出統計",
        href: "https://win.dgbas.gov.tw/dgbas03/bs7/calendar/MetaQry.asp?QM=0&MetaId=1667&fyear=2021&fmonth=1",
        org: "衛福部",
        issue: ["保險保障"]
      },
      {
        report: "簡易生命表",
        href: "https://www.moi.gov.tw/stat/node.aspx?cate_sn=&belong_sn=4883&sn=5992",
        org: "內政部",
        issue: ["平均壽命"]
      },
      {
        report: "僑外及陸資投資事業營運狀況調查分析報告",
        href: "https://www.moeaic.gov.tw/chinese/news_stRt.jsp",
        org: "投審會",
        issue: ["國際投資"]
      },
      {
        report: "對海外投資事業營運狀況調查分析報告",
        href: "https://www.moeaic.gov.tw/chinese/news_stRt.jsp",
        org: "投審會",
        issue: ["國際投資"]
      },
      {
        report: "國人赴海外工作人數統計",
        href: "https://www.stat.gov.tw/lp.asp?ctNode=6395&CtUnit=1818&BaseDSD=29&mp=4",
        org: "主計總處",
        issue: ["海外就業"]
      },
      {
        report: "就學貸款統計資料",
        href: "https://helpdreams.moe.edu.tw/NewsContent.aspx?id=195",
        org: "教育部",
        issue: ["就學貸款"]
      },
      {
        report: "薪資中位數及分布統計",
        href: "https://www.stat.gov.tw/np.asp?ctNode=6356&mp=4",
        org: "主計總處",
        issue: ["薪資"]
      },
    ]
  }
]

const everyMonsData = [
  {
    report: "物價統計",
    href: "https://www.stat.gov.tw/np.asp?ctNode=485&mp=4",
    org: "主計總處",
    issue: ["物價穩定"]
  },
  {
    report: "海關進出口貿易初步統計",
    href: "https://www.mof.gov.tw/multiplehtml/384fb3077bb349ea973e7fc6f13b6974?categoryCode=STAT_EXP",
    org: "經濟部",
    issue: ["進口出口"]
  },
  {
    report: "每月國際貿易情勢分析",
    href: "https://www.trade.gov.tw/Pages/List.aspx?nodeID=1591",
    org: "經濟部",
    issue: ["進口出口"]
  },
  {
    report: "能源統計月報",
    href: "https://www.moeaboe.gov.tw/ECW/populace/web_book/WebReports.aspx?book=M_CH&menu_id=142",
    org: "經濟部",
    issue: ["再生能源"]
  },
  {
    report: "集中交易市場成交金額投資人類別比例表",
    href: "https://www.twse.com.tw/zh/statistics/statisticsList?type=07&subType=262",
    org: "臺灣證券交易所",
    issue: ["市場動向"]
  },
  {
    report: "信義房價指數",
    href: "https://www.sinyinews.com.tw/quarterly",
    org: "信義房屋",
    issue: ["房價負擔"]
  },
  {
    report: "Google COVID-19社區人流趨勢報告",
    href: "https://www.google.com/covid19/mobility/?hl=zh-TW",
    org: "Google",
    issue: ["疫情控制"]
  },
  {
    report: "OXFORD全球疫情控管指數",
    href: "https://ourworldindata.org/grapher/covid-stringency-index",
    org: "牛津大學",
    issue: ["疫情控制"]
  },
  {
    report: "本月景氣動向調查",
    href: "https://www.tier.org.tw/forecast/forecast.aspx",
    org: "台經院",
    issue: ["景氣動向"]
  },
  {
    report: "消費者信心指數調查報告",
    href: "http://rcted.ncu.edu.tw/cci.asp",
    org: "中央大學台灣經濟發展研究中心",
    issue: ["景氣動向"]
  }
]

export const StyledText = styled(Text)`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`

const theader = [
  { label: '報告書', width: '470px' },
  { label: '發布單位', width: '376px' },
  { label: '議題', width: '130px' },
]

const TilteModule = ({ data, index }) => (
  <Flex alignItems={'flex-end'} pb={responsive("0.5rem", 0)} borderBottom={responsive('1px solid', 'none')}>
    <Flex fontWeight={700}>
      <Text mt="0.5em" fontSize={responsive('1.125em', '1.5em')}>{data.mon}</Text>
      <Text lineHeight={1} mx={responsive('0.5rem', '1.25rem')} fontSize={responsive('3.75em', '6em')}>{index + 1}</Text>
    </Flex>
    <Text fontWeight={500} fontSize={responsive('1.125em', '2em')}>月數據報告書</Text>
  </Flex>
)

export const OrgDataBlock = ({ org, info }) => (
  <Stack fontWeight={500} spacing={1}>
    <Text fontSize={responsive('0.75em', '1em', 'auto')}>{org}</Text>
    {info.map((data, i) => (
      <Stack spacing={1} key={i}>
        <Text fontWeight={700} textDecoration={'underline'} fontSize={responsive('0.875em', '1.125em', 'auto')}>
          <Link href={data.href}>{data.report}</Link>
        </Text>
        <HStack spacing={1}>
          {data.issue.map(tag => <Text key={tag} fontSize={responsive('0.75em', '1em', 'auto')}>#{tag}</Text>)}
        </HStack>
      </Stack>
    ))}
  </Stack>
)

const PaginationButton = (props) => <Button p="0" variant={'transparent'} color="black" {...props} />

const CustomTd = ({ children, ...props}) => <Td pt={"1.125rem"} pb={0} pl="1.5rem" pr="0" borderBottom="none" {...props}>{children}</Td>
const DataReport = () => {
  const [pageCount, handlePageClick] = useState(0)
  const [active, setMon] = useState(() => getMonth(new Date()))
  const { isOpen, onOpen, onClose } = useDisclosure()
  const transformData = useMemo(() => sortBy(monthData[active].data.concat(everyMonsData).reduce((res, d) => {
    if (res.some(obj => obj.org === d.org)) {
      res[res.findIndex(data => data.org === d.org)].info.push(d)
    } else {
      res.push({ org: d.org, info: [{ ...d }]})
    }
    return res
  }, []), (o) => o.info.length), [active])
  // console.log(active)
  return (
    <Section
      title="從數據報告找趨勢靈感"
      desc={'在製作專題或商業報告時，想觀察社會趨勢與進行分析，卻找不到適合的資料嗎？\n我們精選出100個重要的國內外報告，並用議題做分類，讓你能從資料海中找出一抹希望！'}
    >
      <Box ml="2.5em" pos="relative" mt={'3.375em'} as={Media} greaterThanOrEqual="lg">
        <StaticImage src="./image/calendar_board.png" alt="background-picture" />
        <Box pos={'absolute'} top="0" mt="5.275em" left="-2.5em" right="3.25em">
          <Tabs color="black" onChange={(index) => setMon(index)} defaultIndex={active}>
            <TabList px="1.25em" border="none" position={'relative'}>
              {monthData.map(({mon}) => (
                <Tab
                  height={'100%'}
                  alignItems={'flex-start'}
                  width={1 / 12}
                  fontSize="1.75em"
                  fontWeight={700}
                  bg="white"
                  border="1px solid"
                  borderRadius={'0.625em 0.625em 0 0'}
                  pb="1rem"
                  _selected={{
                    borderBottom: 'none',
                    py: '0.5rem',
                    zIndex: 2,
                    _hover: {
                      transform: 'none'
                    }
                  }}
                  _hover={{
                    transform: 'translateY(-0.5rem)'
                  }}
                  transition="all 0.25s"
                  transformOrigin={'0 0'}
                  onClick={() => {
                    if (typeof window.gtag === 'function') {
                      window.gtag('event', 'click_button', {
                        button_name: `data_${mon}`
                      });
                    }
                  }}
                  key={mon}
                >{mon}</Tab>
              ))}
            </TabList>
            <Box pos="relative" mt="-0.5em">
              {range(2).map(num => (
                <Box pos="absolute" border={`${2 - num}px solid`} bg="white" borderRadius="1em" top="0" left="0" right={`-${12 * (2 - num)}px`} bottom="2.5em" key={num} />
              ))}
              <TabPanels bg="white" border={'1px solid'} borderRadius="1em" pos="relative">
                {monthData.map((d, i) => (
                  <TabPanel minH={'41.5em'} px={"1.875em"} pt={'1.875em'} pb={'2em'} key={d.mon}>
                    <TilteModule data={d} index={i} />
                    <Table mt={'0.625em'}>
                      <thead>
                        <Tr borderTop={'1px solid'}>
                          {theader.map((th) => (
                            <Th py="0.625em" width={th.width} fontWeight={500} fontSize={'1.25em'} color="black" borderColor={'black'} key={th.label}>{th.label}</Th>
                          ))}
                        </Tr>
                      </thead>
                      <tbody>
                        {chunk(d.data.concat(everyMonsData), 9)[pageCount]?.map((data, i) => (
                          <Tr fontWeight={500} key={i}>
                            <CustomTd
                              _hover={{ color: 'reportGray' }}
                              fontWeight={700}
                              fontSize={'1.25em'}
                              pl="1.75rem"
                              textDecoration="underline"
                              lineHeight={1.25}
                            >
                              <Link href={data.href}>
                                {data.report}
                              </Link>
                            </CustomTd>
                            <CustomTd>{data.org}</CustomTd>
                            <CustomTd><HStack spacing={2}>{data.issue.map(tag => <Text key={tag}>#{tag}</Text>)}</HStack></CustomTd>
                          </Tr>
                        ))}
                      </tbody>
                    </Table>
                    <Flex pos="absolute" bottom="0.625em" left="50%" transform={'translateX(-50%)'} justifyContent={'center'} alignItems="center">
                      {!!pageCount && <PaginationButton onClick={() => handlePageClick(pageCount - 1)} as={IconButton} icon={<IoIosArrowBack size="1em" />} />}
                      {range(chunk(d.data.concat(everyMonsData), 9).length).map(page =>
                        <PaginationButton color={page === pageCount ? 'black' : 'reportGray'} onClick={() => handlePageClick(page)} mx='0.25rem' key={page}>{page + 1}</PaginationButton>
                      )}
                      {!(pageCount === chunk(d.data.concat(everyMonsData), 9).length -1) && <PaginationButton onClick={() => handlePageClick(pageCount + 1)} as={IconButton} icon={<IoIosArrowForward size="1em" />} />}
                    </Flex>
                  </TabPanel>
                ))}
              </TabPanels>
            </Box>
          </Tabs>
        </Box>
      </Box>
      <Box as={Media} lessThan="lg" mt="2.625em">
        <Swiper
          slidesPerView={5}
          spaceBetween={8}
          style={{ overflow: 'visible' }}
        >
          {monthData.map(({mon}, i) => (
            <SwiperSlide key={mon}>
                <Button
                  width={'100%'}
                  bg={active === i ? 'white' : 'rgba(229, 229, 229, 0.6)'}
                  color={active === i ? 'buttonTextGray' : 'white'}
                  border="1px solid"
                  py="0.25rem"
                  borderRadius={'5px'}
                  fontSize="15px"
                  onClick={() => setMon(i)}
                >
                  {mon}
                </Button>
            </SwiperSlide>
          ))}
        </Swiper>
        <Box pos="relative" mt="2.375em">
          <StaticImage src="./image/board_mobile.png" alt="background-picture-board" />
          <Box
            pos="absolute"
            top="12%"
            bg="white"
            borderRadius={'5px'}
            border="1px
            solid"
            color="black"
            pb="0.875em"
            pt="0.625em"
            px="1.125em"
            left={'4%'}
            right={'5.5%'}
            bottom="5%"
          >
            <TilteModule data={monthData[active]} index={active} />
            <Stack spacing={1} mt="1.125em">
              {[...transformData].reverse().slice(0, last(transformData).info.length > 1 ? 1 : 2).map(({ org, info }, key) => (
                <OrgDataBlock org={org} info={info.slice(0, 3)} key={key} />
              ))}
              <Box textAlign={'center'}>
                <Button
                  width={responsive('auto', '27.5%')}
                  variant={'transparent'}
                  fontSize={responsive('0.75em', '1em', 'auto')}
                  fontWeight={400}
                  border="1px solid"
                  onClick={onOpen}
                  color="#9C9C9C"
                  rightIcon={<IoIosArrowDown size="1rem" />}
                >
                  查看更多
                </Button>
              </Box>
            </Stack>
            <ReportModal isOpen={isOpen} onClose={onClose} data={transformData}>
              <TilteModule data={monthData[active]} index={active} />
            </ReportModal>
          </Box>
        </Box>
      </Box>
    </Section>
  )
}

export default DataReport
