import React, { useEffect, useRef, useState } from 'react'
import { Box, Center, Circle, Flex, Icon, IconButton, Text } from '@chakra-ui/react'
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { BiUpload } from "react-icons/bi";
import { Swiper, SwiperSlide  } from 'swiper/react';
import { MdPlayArrow, MdVisibilityOff, MdVisibility } from "react-icons/md";
import BackgroundImage from 'components/BackgroundImage';
import { Media, responsive } from 'contexts/responsive';
import useFirestore from 'hooks/useFirestore';
import "swiper/css";

import Link from './Link';
import Button from './Button';

const StyledText = styled(Text)`
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`
const NameText = styled(Text)`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`

const StyledSwiper = styled(Swiper)`
  @media screen and (max-width: 768px) {
    overflow: visible!important;
    .swiper-slide.swiper-slide-prev {
      transform: translateX(25%);
    }
    .swiper-slide.swiper-slide-active {
      transition: transform 500ms;
      transform-origin: center;
      transform: scale(1.1);
      z-index: 5
    }
    .swiper-slide.swiper-slide-next {
      transform: translateX(-25%);
    }
  }
`

const zhDate = date => date.replace(/^(\d{4})-0?(\d+)-0?(\d+).+$/, '$1年$2月$3日')

const Post = ({ post, fb, handleUpdate, transit, isAdmin, ...props }) => (
  <Box {...props} pos="relative" width={responsive('70%', 'auto')} height="100%" mx="auto">
    <Flex
      as={Link}
      color="black"
      href={fb ? `https://www.facebook.com/${post.id}` : post?.url}
      pt={'1.375em'}
      pb={'1.25em'}
      bg={'white'}
      flexDir={fb ? 'column' : 'column-reverse'}
      px={responsive('1.625em', '1.5em')}
      borderRadius="0.625em"
      height="100%"
    >
      <Box pt="1em">
        <Flex alignItems={'center'}>
          <Circle size={'1.875em'} p="0.25em" border="1px solid" mr="0.375em">
            <Box width="100%"><BackgroundImage src={post.avatar} ratio={1} /></Box>
          </Circle>
          <Box>
            <NameText fontSize={'0.75em'} fontFamily="inter">{post.from_name}</NameText>
            <Text fontSize={'0.75em'}>{format(new Date(post.ts_iso_8601), 'yyyy年M月d日')}</Text>
          </Box>
        </Flex>
        <Box mt="0.875rem" height={'3.375em'}>
          <StyledText fontSize={'0.75em'} whiteSpace={'pre-wrap'}>{post.message || post.text}</StyledText>
        </Box>
      </Box>
      <Box mt={responsive('1em', fb ? '0.5em' : '1.375em')}><BackgroundImage src={post.image} ratio={1} /></Box>
    </Flex>
    <Box pos="absolute" top={responsive('0.375em', '0.5em')} right={'0.5em'}>
      <IconButton
        bg="white"
        p="0.25rem"
        width="1.5em"
        height="1.5em"
        minW={'auto'}
        isRound
        border="1px solid"
        color="black"
        _hover={{
          color: 'dotGray'
        }}
        isDisabled={transit}
        onClick={() => handleUpdate(post.id)}
        icon={isAdmin ? <MdVisibility size="1em" /> : <MdVisibilityOff size="1em"/>}
      />
    </Box>
  </Box>
)

const StyledButton = styled(Button)`
  transition: all 0.5s;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const SocialSwiper = ({ data, px, arrowBg, fb, loop, good, isAdmin }) => {
  const [transit, setTransit] = useState()
  const { firestore, doc, updateDoc } = useFirestore()
  const [activeIndex, setIndex] = useState(0)
  const [isLoading, setIsLoading] = useState()

  const handleUpdate = async(id) => {
    const socialRef = doc(firestore, fb ? (good ? "fb1" : "fb") : "ig", id)
    setTransit(true)
    await updateDoc(socialRef, {
      ts: isAdmin ? 1 : 0
    }).then(
      setTransit(false)
    );
  }
  const ref = useRef()
  useEffect(() => {
    if(!ref.current) return null
  }, [ref])
  return (
    <Box pos="relative">
      {isAdmin && (
        <Box pos="absolute" top={'-2em'} transform="translateY(-100%)" right="0em">
          <Button
            _hover={{}}
            _active={{}}
            color="black"
            bg="transparent"
            p="0"
            minW="auto"
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true)
              let formData = new FormData()
              formData.append('type', fb ? (good ? "fb1" : "fb") : "ig")
              formData.append('data', JSON.stringify(data))
              fetch(
                'https://script.google.com/macros/s/AKfycbwAS1tntcqN8C2EF9oKq5N_pkXsL1_FmR4FvECJDmwJ-NeEirenrpYDY7miut0VyxQr/exec',
                {
                  method: 'POST',
                  body: formData,
                  mode: 'no-cors'
                }
              ).then(async () => {
                console.log('succeed to submit')
                setIsLoading(false)
              }).catch(error => {
                console.log(error, 'fail to submit')
                setIsLoading(false)
              })
            }}
          >
            <Icon as={BiUpload} w={6} h={6} />
          </Button>
        </Box>
      )}
      <StyledSwiper
        loop={loop ? false : true}
        spaceBetween={20}
        slidesPerView={1}
        ref={ref}
        onSlideChange={(swiper) => {
          setTransit(true)
          setIndex(swiper.activeIndex)
          setTimeout(() => {
            setTransit(false)
          }, 300);
        }}
        breakpoints={{
          769: {
            slidesPerView: 2
          },
          992: {
            slidesPerView: 4,
          }
        }}
      >
        {data.map((post, i) => (
          <SwiperSlide style={{ height: 'auto' }} key={i}>
            {/* {isArray(post) ? (
              <Box>
                {post.map((d, key) => (
                  <Post post={d} mt={key && '2.5em'} key={key} handleUpdate={handleUpdate} transit={transit} />
                ))}
              </Box>
            ) : ( */}
              <Post isAdmin={isAdmin} post={post} fb={fb} handleUpdate={handleUpdate} transit={transit} />
            {/* )} */}
          </SwiperSlide>
        ))}
      </StyledSwiper>
      <Media greaterThanOrEqual='md'>
        <Center pos={'absolute'} width={px} top="50%" left="0" transform={`translate(-100%, -50%)`}>
          <Circle size="3.625em" as={StyledButton} variant="link" trackerName={`social_${fb ? (good ? "fb1" : "fb2") : "ig"}_left`} disabled={(loop && activeIndex === 0) || transit} transform={'rotate(180deg)'} onClick={() => ref?.current?.swiper.slidePrev()} bg={arrowBg} >
            <MdPlayArrow size="2em" />
          </Circle>
        </Center>
        <Center pos={'absolute'} width={px} top="50%" right="0" transform={'translate(100%, -50%)'}>
          <Circle size="3.625em" as={StyledButton} variant="link" trackerName={`social_${fb ? (good ? "fb1" : "fb2") : "ig"}_right`} disabled={(loop && activeIndex + 4 === data.length) || transit} bg={arrowBg} onClick={() => ref?.current?.swiper.slideNext()}>
            <MdPlayArrow size="2em" />
          </Circle>
        </Center>
      </Media>
    </Box>
  )
}

export default SocialSwiper
