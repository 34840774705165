import React from 'react'
import { Center, Spinner } from '@chakra-ui/react'
import SocialSwiper from 'components/SocialSwiper'
import ensureFisrestore from 'contexts/firebase/ensureFisrestore'
import useFirestoreInvPosts from 'hooks/useFirestoreInvPosts'
import { Media } from 'contexts/responsive'

const InvIgPosts = (props) => {
  const { data } = useFirestoreInvPosts('ig', 12)
  // console.log(data, 'ig')
  if (!data) return <Center><Spinner /></Center>
  return (
    <>
      <Media greaterThanOrEqual='md'>
        <SocialSwiper data={data} loop {...props} />
      </Media>
      <Media lessThan='md'>
        <SocialSwiper data={data} loop isAdmin={props.isAdmin} />
      </Media>
    </>
  )
}

export default ensureFisrestore(InvIgPosts)
