import React from 'react';
import { Button as ChButton, forwardRef } from "@chakra-ui/react";

import Link from './Link'

const Button = forwardRef(({ href, to, onClick, trackerName, ...props }, ref) => {
  const handleTrackEvent = (e) => {
    if (onClick) {
      onClick(e);
    }
    if (trackerName) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'click_button', {
          button_name: trackerName
        });
      }
      // if (typeof window.fbq === 'function') {
      //   window.fbq('track', `Click${capitalize(trackerName)}`);
      // }
    }
  };
  if (href || to) return <ChButton as={p => <Link {...p} href={href} to={to} ref={ref} />} onClick={handleTrackEvent} {...props} />
  return <ChButton ref={ref} onClick={handleTrackEvent} {...props} />
})

export default Button
