import React from 'react'
import {
  Modal,
  ModalContent,
  ModalCloseButton,
  Box,
  Stack,
} from '@chakra-ui/react'
import { OrgDataBlock } from 'containers/HomePage/DataReport'

const ReportModal = ({ isOpen, onClose, data, children }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent border={'1px solid'} pt="3.5em" pb="1.125em" px="0.875em" borderRadius={'15px'} bg="borderGray" width={'87%'}>
        <ModalCloseButton fontSize={'1.5em'} bg="white" border="1px solid" box-shadow="2px 2px 4px rgba(0, 0, 0, 0.25)" borderRadius={'0.25rem'} right="1.5rem" top="0.625rem" />
        <Box px="1em" pt="0.625em" pb="2.75em" bg="white" borderRadius={'5px'} border={'1px solid'}>
          {children}
          <Stack spacing={4} mt="1.125em">
            {data.map(({ org, info }, key) => (
              <OrgDataBlock org={org} info={info} key={key} />
            ))}
          </Stack>
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default ReportModal
