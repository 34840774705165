import React, { useMemo, useState } from 'react'
import {
  Box,
  Flex,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from '@chakra-ui/react'

import Section from 'components/Section'
import Button from 'components/Button'
import { Media, responsive } from 'contexts/responsive'
import FbPosts from './FbPosts'
// import FbFeaturedPosts from './FbFeaturedPosts'
import IgPosts from './IgPosts'
import TwitterEmbed from './TwitterEmbed'
import InvFbPosts from './InvFbPosts'
import InvIgPosts from './InvIgPosts'
import Link from 'components/Link'

const px = '4.625em'

const social = [
  {
    name: 'Instagram 資訊設計靈感',
    desc: '每週皆會定期更新 #datavisualizations #dataviz 相關熱門貼文，',
    bg: 'linear-gradient(335.41deg, rgba(84, 77, 185, 0.96) 22.14%, rgba(218, 94, 78, 0.67) 76.3%)',
    buttonBg: 'rgba(225, 130, 117, 0.45)',
    activeBg: 'rgba(218, 94, 78, 0.67)',
    data: <IgPosts px={px} arrowBg="arrowIg" />,
    invdata: <InvIgPosts px={px} arrowBg="arrowIg" isAdmin />,
    type: 'Instagram'
  },
  {
    name: 'Twitter 資訊設計靈感列表',
    desc: '不定期更新 #資訊設計平台 #資訊設計師 的追蹤名單，歡迎追蹤清單了解最即時的相關訊息！',
    bg: 'linear-gradient(145.97deg, rgba(74, 153, 233, 0.74) 32.46%, rgba(74, 153, 233, 0.47) 83.46%)',
    buttonBg: 'rgba(147, 200, 255, 0.50)',
    activeBg: 'rgba(74, 153, 233, 0.74)',
    data: <TwitterEmbed px={px} />,
    type: 'Twitter'
  },
  {
    name: 'Facebook 資訊設計靈感',
    desc: '由 RE:LAB 精選 20+ 個高品質的資訊設計粉絲專頁，',
    bg: 'linear-gradient(145.97deg, rgba(50, 114, 223, 0.486) 32.46%, rgba(79, 166, 246, 0.348) 83.46%)',
    buttonBg: 'rgba(112, 154, 224, 0.38)',
    activeBg: 'rgba(50, 114, 223, 0.48)',
    data: <FbPosts px={px} arrowBg="arrowFb" />,
    invdata: <InvFbPosts px={px} arrowBg="arrowFb" isAdmin />,
    type: 'Facebook'
  },
]

const SocialModule = ({ d, isAdmin }) => (
  <Box bg={responsive('transparent', d.bg)} borderRadius="15px" pt={'1.25em'} pb={responsive('2.875em', '2.25em')} px={responsive(0, px)}>
    <Box>
      <Text fontSize={responsive('1em', '1.5em')} fontWeight={700}>{d.name}</Text>
      <Text mt={responsive('0.625rem', '0.75rem')} fontSize={responsive('0.75em', '1em')} letterSpacing="0.08em">
        {d.desc}
        {d.type !== 'Twitter' && <><br />若眼尖的你有發現不相關的貼文，點擊卡片右上角的 icon 即可幫所有人隱藏不相關的貼文！</>}
      </Text>
    </Box>
    <Box pos="relative" mt={responsive(d.type === 'Twitter' ? '1.5em' : '2.875em', '2em')}>
      {isAdmin ? (d?.invdata) : d.data}
      {d.type === 'Twitter' && (
        <Box textAlign={'center'} mt="1.625em">
          <Button
            trackerName="social_twitter"
            px={responsive('1rem', '2rem')}
            fontSize={responsive('0.875rem', '1.25rem')}
            py={responsive('0.375rem', "0.5rem")}
            borderRadius={responsive('0.375rem', '0.75rem')}
            href="https://twitter.com/RELAB11871907/lists/1552497134930644992?ref_src=twsrc%5Etfw"
          >在Twitter上查看</Button>
        </Box>
      )}
    </Box>
  </Box>
)

const Social = ({ isAdmin, ...props }) => {
  const [type, setType] = useState(social[0].type)
  const socialData = useMemo(() => social.filter(d => d.type === type), [type])
  return (
    <Section
      title={!isAdmin && "從社群找話題靈感"}
      desc={!isAdmin && <>每天在滑 Facebook、Instagram 的時候都接觸不到資訊設計的相關靈感嗎？想知道社群上有什麼值得參考的案例嗎？<br/><br/>我們和社群數據分析與趨勢洞察的專業品牌 <Link textDecoration="underline" href="https://zh-tw.qsearch.cc/">QSearch</Link> 合作，利用工具即時監測話題聲量趨勢，<br/>每個區塊將以固定頻率進行更新，歡迎不定時上來看看社群上最熱門的資訊設計案例！</>}
      // bg={responsive(socialData[0].bg, 'transparent')}
      mx={responsive('-1.25em', 0)}
      px={responsive('1.25em')}
      {...props}
    >
      {!isAdmin && (
        <Flex pt={responsive('0.625em', '1.5em')} pb={responsive('1.875em', '2.5em')} mt={responsive('0.875em', '2.5em')} borderTop="1px dashed #E5E5E5" alignItems="center">
          <Box mr={responsive('1.375em', '13em')} fontSize={responsive('0.75em', '1.125em')} flex={responsive(1, 'unset')}>
            若對於監測社群趨勢與數據分析的服務有興趣，想進一步和我們合作，<br />
            請點選「合作洽詢」按鈕，填答完相關資訊將有專人與你接洽唷！
          </Box>
          <Button trackerName="social_collab" href="https://relab.cc/contact/" px={responsive('1.375rem', '4rem')} fontSize={responsive('0.875em', '1.125em')} border="1px solid white">合作洽詢</Button>
        </Flex>
      )}
      <Stack as={Media} greaterThanOrEqual="md" mx={"-2.5em"} spacing={'3.75em'} mt={isAdmin && '1em'}>
        {social.map((d) => (
          <SocialModule d={d} isAdmin={isAdmin} key={d.type} />
        ))}
      </Stack>
      <Box as={Media} lessThan='md' mx="-1.25em">
        <Tabs>
          <TabList alignItems={'flex-end'} px="1em" mx="-7.5px" border="none">
            {social.map(({ type, buttonBg, activeBg }) => (
              <Tab
                bg={buttonBg}
                px="1.375em"
                fontSize="0.75rem"
                py="0.375rem"
                mx="7.5px"
                width={1 / 3}
                borderBottom="none"
                mb="0"
                fontWeight={700}
                // border="1px solid"
                // borderColor="borderGray"
                borderRadius="6px 6px 0px 0px"
                onClick={() => setType(type)}
                _hover={{
                  bg: activeBg
                }}
                _active={{
                  bg: activeBg
                }}
                _selected={{
                  color: 'white',
                  bg: activeBg
                }}
                key={type}
              >{type}</Tab>
            ))}
          </TabList>
          <TabPanels mixBlendMode="normal" bg={socialData[0].bg} border="none">
            {social.map((d) => (
              <TabPanel px="1.25em" py="0" pos="relative" key={d.type}>
                {/* <Box pos="absolute" top="0" right="0" left="0" bottom="0" /> */}
                <SocialModule isAdmin={isAdmin} d={d} />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Section>
  )
}

export default Social
