import React from 'react'
import { Global } from '@emotion/react'

import { MediaContextProvider } from './src/contexts/responsive'
import HeaderProvider from './src/contexts/header/Provider'
import globalStyles from './global-styles'
import FirebaseProvider from 'contexts/firebase/provider'

const Providers = ({ element }) => (
  <FirebaseProvider>
    <MediaContextProvider>
      <HeaderProvider>
        <>
          <Global styles={globalStyles} />
          {element}
        </>
      </HeaderProvider>
    </MediaContextProvider>
  </FirebaseProvider>
)

export default Providers
