import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import WebReferModule from 'components/WebReferModule'
import Section from 'components/Section'

const materials = [
  {
    title: '照片素材',
    image: <StaticImage src="./image/photo.png" alt="photo" />,
    web: [
      {
        name: "pixTA",
        trackerName: 'material_photo_pixta',
        desc: "台灣的素材網站，適合要找亞洲地區的圖片、影音素材網站",
        href: "https://tw.pixtastock.com/?fbclid=IwAR2Q0ZwLdg7J_5faM07_QvL5ag651yRPGX5-qLFaHtUX4PU4hIIML4vFMU8"
      },
      {
        name: "Foodies Feed",
        trackerName: 'material_photo_foodiesfeed',
        desc: "可免費商用的食物照片網站，裡面還有“MAGAZINE”的分頁，可以欣賞攝影合成作品",
        href: "http://foodiesfeed.com/"
      },
      {
        name: "istock",
        trackerName: 'material_photo_istock',
        desc: "大型的綜合素材網站，包含相片、插圖、影片、音樂等，有分免費或需付費的素材。",
        href: "https://www.istockphoto.com/"
      },
      {
        name: "photoAC",
        trackerName: 'material_photo_photoAC',
        desc: "日本的免費圖片網站，適合找亞洲地區的素材，而且都可以免費商用。",
        href: "https://tw.photo-ac.com/"
      },
      // {
      //   name: "pexels",
      //   desc: "豐富的圖片素材資料庫，來源從素材網站到個人攝影師都有，內建『從顏色來找圖』的功能",
      //   href: "https://www.pexels.com/"
      // },

      {
        name: "Everypixel",
        trackerName: 'material_photo_everypixel',
        desc: "「智慧型」免費圖庫搜尋引擎，用演算法幫你找出「好看」的照片",
        href: "https://www.everypixel.com/"
      },
      {
        name: "shutterstock",
        trackerName: 'material_photo_shutterstock',
        desc: "這是一個擁有非常大量圖片、影片等媒體資源的付費網站。",
        href: "https://www.shutterstock.com/zh-Hant/",
      },
    ]
  },
  {
    title: '透明圖片素材',
    image: <StaticImage src="./image/transparent_photo.png" alt="transparent-photo" />,
    web: [
      {
        name: "Pngtree",
        trackerName: 'material_png_Pngtree',
        desc: "免費設計素材庫，特別的是有提供大量的PNG去背圖片，但要注意素材使用規範",
        href: "https://zh.pngtree.com/freepng/Recently-Download?sce=ma&id=cla"
      },
      {
        name: "pngimg",
        trackerName: 'material_png_pngimg',
        desc: "號稱最大的 PNG 透明背景圖庫，可用於個人個人及非商業性用途",
        href: "http://pngimg.com/"
      },
    ]
  },
  {
    title: '向量素材',
    image: <StaticImage src="./image/vector.png" alt="vector" />,
    web: [
      {
        name: "freepik",
        trackerName: 'material_vector_freepik',
        desc: "目前最大的向量圖庫，素材專業度高、可編輯修改及自由分享",
        href: "https://www.freepik.com/home"
      },
      {
        name: "VectorPortal",
        trackerName: 'material_vector_vectorPortal',
        desc: "為設計師而生的向量圖庫 ，擁有最多「專業設計水準」的向量素材，可免費商用",
        href: "https://www.vectorportal.com/"
      },
      {
        name: "open peeps",
        trackerName: 'material_vector_openpeeps',
        desc: "風格鮮明的免費手繪向量圖庫，以人物為主，可使用內建模組快速更換手臂、腿、表情、服裝等快速製作出理想的人物造型",
        href: "https://www.openpeeps.com/"
      },
    ]
  },
  {
    title: 'ICON素材',
    image: <StaticImage src="./image/icon.png" alt="icon" />,
    web: [
      {
        name: "flaticon",
        trackerName: 'material_icon_flaticon',
        desc: "精美的 ICON 向量圖庫，提供 SVG、PNG、EPS 與 PSD 等多種格式，大多提供免費的商業使用",
        href: "https://www.flaticon.com/"
      },
      {
        name: "iconshock",
        trackerName: 'material_icon_iconshock',
        desc: "提供各種樣式、風格的ICON，特別的是你可以使用內建色調盤來改變 icons 的顏色並下載",
        href: "https://www.iconshock.com/"
      },
    ]
  },
]

const Material = () => {
  return (
    <Section
      title="用素材資源事半功倍"
      desc={'腦海中已經有畫面，卻不知道改去哪裡尋找合適素材嗎？\n我們為你整理了4種常用的資源網站，讓你的作品畫面更有故事性！'}
    >
      <WebReferModule data={materials} leftProps={{ textAlign: 'center' }} />
    </Section>
  )
}

export default Material
