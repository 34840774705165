import { Box, Center, Circle, Flex, Stack, Text } from '@chakra-ui/react'
import Button from 'components/Button'
import Link from 'components/Link'
import { Media, responsive } from 'contexts/responsive'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import FooterCircle from './FooterCircle'

const entrance = [
  {
    image: <StaticImage src="./image/relab.png" alt="relab-logo" />,
    hoverImage: <StaticImage src="./image/relab_white.png" placeholder="none" loading="eager" alt="relab-logo-white" />,
    title: 'RE:LAB 官網',
    trackerName: 'footer_relab',
    desc: '十年來的作品大本營，歡迎找我們合作！',
    href: 'https://relab.cc'
  },
  {
    image: <StaticImage src="./image/what_the_data.png" alt="what_the_data-logo" />,
    hoverImage: <StaticImage src="./image/what_the_data_white.png" placeholder="none" loading="eager" alt="what_the_data-logo-white" />,
    title: '台灣發生什麼數',
    trackerName: 'footer_whatthedata',
    desc: '用有趣的數據和實驗，一起認識台灣大小事！',
    href: 'https://whatthedata.cc/'
  },
  {
    image: <StaticImage src="./image/open_lab.png" alt="openlab-logo" />,
    hoverImage: <StaticImage src="./image/open_lab_white.png" placeholder="none" loading="eager" alt="info-logo-white" />,
    title: 'OOOPEN Lab',
    trackerName: 'footer_openlab',
    desc: '2 小時就能輕鬆製作好玩遊戲與測驗！',
    href: 'https://ooopenlab.cc/'
  },
  {
    image: <StaticImage src="./image/hi.png" alt="hello_islander-logo" />,
    hoverImage: <StaticImage src="./image/hi_white.png" placeholder="none" loading="eager" alt="hello_islander-logo-white" />,
    title: '島民集合',
    trackerName: 'footer_helloislander',
    desc: '用資訊懶人包帶你看見不同面向的台灣文化！',
    href: 'https://helloislander.cc/'
  },
]

const Footer = () => {
  return (
    <Box bg="white" color="black" px={responsive('1.875em', '5.25em')} py={'1.75em'} borderTop="1px solid">
      <Media greaterThanOrEqual='lg'>
        <Flex alignItems={'center'} justifyContent={'space-between'}>
          <Box pos="relative" width={'230px'}>
            <Text fontSize="1.5em" fontWeight={700}>RE:LAB其他基地入口</Text>
          </Box>
          {entrance.map((d, i) => (
            <Flex flexDirection={'column'} pos="relative" key={i}>
              <Button variant="link" trackerName={d.trackerName} href={d.href}>
                <FooterCircle d={d} />
              </Button>
            </Flex>
          ))}
        </Flex>
        <Flex justifyContent={'space-between'} alignItems="flex-end" mt="0.75em">
          <Box pos="relative" width={'230px'}>
            <Text whiteSpace={'nowrap'} fontSize="0.875em">Copyright © {new Date().getFullYear()}. All rights reserved.</Text>
          </Box>
          {entrance.map((d, i) => (
            <Button variant="link" width={'144px'} color="black" trackerName={d.trackerName} href={d.href} key={i}>
              <Text textAlign={'center'} fontSize="0.875em" fontWeight={700}>{d.title}</Text>
              <Text fontSize="0.875em">{d.desc}</Text>
            </Button>
          ))}
        </Flex>
      </Media>
      <Box as={Media} lessThan='lg'>
        <Text fontSize="0.75em" textAlign={'center'} fontWeight={700}>RE:LAB其他基地入口</Text>
        <Stack mt="1em" mb="1.125em" spacing={6}>
          {entrance.map((d, i) => (
            <Flex as={Button} variant="link" color="black" trackerName={d.trackerName} justifyContent={'center'} alignItems={'center'} href={d.href} key={i}>
              <Circle size="4.75em" border={'1px solid'} px="0.375em" overflow={'hidden'} mr="0.875em">
                {d.image}
              </Circle>
              <Box width="14.25em">
                <Text fontSize="0.75em" fontWeight={700}>{d.title}</Text>
                <Text fontSize="0.75em">{d.desc}</Text>
              </Box>
            </Flex>
          ))}
        </Stack>
        <Text textAlign={'center'} whiteSpace={'nowrap'} fontSize="0.75em">Copyright © {new Date().getFullYear()}. All rights reserved.</Text>
      </Box>
    </Box>
  )
}

export default Footer
