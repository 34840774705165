import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { Media, responsive } from 'contexts/responsive'

import DataBlock from './DataBlock'
import DataSwiper from './DataSwiper'

const WebReferModule = ({ data, leftProps }) => {
  return (
    <Box>
      {data.map((port, i) => (
        <Flex flexDirection={responsive('column', 'row')} mt={responsive('3em', '5.25em')} key={i}>
          <Flex justifyContent={responsive(port.image && 'flex-end', 'flex-start')} alignItems={port.image && 'center'} flexDirection={responsive(port.image ? 'row-reverse' : 'column', 'column')} flex={1} mr={responsive(0, '3.125em')} {...leftProps}>
            <Text whiteSpace={'pre-wrap'} fontWeight="bold" letterSpacing="0.2em" fontSize={responsive('1em', '1.5em')}>
              {port.title}
            </Text>
            {port.text && <Text mt={responsive('0.625rem', '1em')} whiteSpace={'pre-wrap'} fontSize={responsive('0.875em', '1em')} letterSpacing="0.08em">{port.text}</Text>}
            {port.image && <Box width={responsive('5em', '9em')} m={responsive('0 0.75rem 0 0', "0 auto")} mt={responsive(0, '1.25em')}>{port.image}</Box>}
          </Flex>
          <Flex as={Media} greaterThanOrEqual="md" flexWrap={'wrap'} mx="-1em" width={'74.4%'}>
            {port.web.map((d, key) => (
              <DataBlock d={d} port={port} index={key} key={key} />
            ))}
          </Flex>
          <Box as={Media} mt="3rem" lessThan="md">
            <DataSwiper port={port} />
          </Box>
        </Flex>
      ))}
    </Box>
  )
}

export default WebReferModule
