import { Box, Collapse, keyframes, Spinner } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { CgScrollV } from "react-icons/cg";
// import Button from 'components/Button'
import { responsive } from 'contexts/responsive'
import styled from '@emotion/styled';

const bounce = keyframes`
  25% {
    transform: translateY(20%);
  }
  50%, {
    transform: translateY(0%);
  }
  75%, {
    transform: translateY(-20%);
  }
`

const AnimeScroll = styled(Box)`
  animation: ${bounce} 1s infinite;
`

const TwitterEmbed = () => {
  const [show, setShow] = React.useState(false)
  const handleToggle = () => setShow(!show)
  useEffect(() => {
    const scriptEle = document.createElement('script')
    scriptEle.src = 'https://platform.twitter.com/widgets.js'
    scriptEle.async = true
    document.body.appendChild(scriptEle)
    setTimeout(() => setShow(true), 5500)
  }, [])
  // if (!show) return <Center><Spinner /></Center>
  return (
    <Box pos="relative" mx={responsive('1.25em', '9.25em')}>
      <Box transition="all 1s" opacity={show ? 1 : 0} height={responsive('290px', '343px')} borderRadius="0.75rem" overflow="auto">
        <a
          className="twitter-timeline"
          href="https://twitter.com/RELAB11871907/lists/1552497134930644992?ref_src=twsrc%5Etfw"
        >
          A Twitter List by RELAB11871907
        </a>
      </Box>
      <Box opacity={show ? 1 : 0} transition="all 1s" pos="absolute" bottom="1em" right={responsive(0, '1em')} color={'scrollIcon'}>
        <AnimeScroll>
          <CgScrollV size="1.5em" />
        </AnimeScroll>
      </Box>
      {!show && <Box pos="absolute" top="50%" left="50%" transform={'translate(-50%, -50%)'}><Spinner /></Box>}
      {/* <Button size="sm" onClick={handleToggle} mt="1rem">
        {show ? '收合' : '展開'}
      </Button> */}
    </Box>
  )
}

export default TwitterEmbed
