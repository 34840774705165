import React, { useMemo, useState } from 'react'
import { Button, Container, Text, Box, Center } from '@chakra-ui/react'
import { Link, Element } from 'react-scroll'
import { Media, responsive } from 'contexts/responsive';
import Reference from './Reference';
import Social from './Social';
import MediaSection from './MediaSection';
import DataReport from './DataReport';
import Tool from './Tool';
import Material from './Material';
import Footer from './Footer';
import useTrackClick from 'hooks/useTrackClick';

export const inspirations = [
  { name:'從參考作品找出呈現靈感', key: 'reference', Comp: Reference },
  { name:'從社群找話題靈感', key: 'social', Comp: Social },
  { name:'從媒體平台找內容行銷靈感', key: 'media', Comp: MediaSection },
  { name:'從數據報告找趨勢靈感', key: 'data', Comp: DataReport },
  { name:'用工具把靈感具象化', key: 'tool', Comp: Tool },
  { name:'用素材資源事半功倍', key: 'material', Comp: Material },
]

// let sec = 0

const IndexPage = ({ location }) => {
  const trackNavClick = useTrackClick('nav')
  const [tag, setTag] = useState(inspirations[0].key)
  // const [zero, setZero] = useState(0)
  // const [one, setOne] = useState(0)
  const isAu = useMemo(() => location?.pathname.includes('au') ,[location?.pathname])
  const sections = useMemo(() => inspirations.slice(0, isAu ? 6 : 4) ,[isAu])
  // useEffect(() => {
  //   const test = setInterval(() => {
  //     sec = sec + 1
  //     let num = Math.floor(Math.random()*2)
  //     if (num) {
  //       setOne(prev => prev + 1)
  //     } else {
  //       setZero(prev => prev + 1)
  //     }
  //     if (sec > 1930) {
  //       clearInterval(test)
  //     }
  //   }, 50)
  // }, [])
  // console.log(zero, one, sec)
  return (
    <Box pos="relative" overflow={responsive('hidden', 'clip')}>
      <Box border="1px solid black">
        <Container>
          <Box pt={responsive('1.75em', '2.125em')} pb={responsive('1.125em', '2.625em')}>
            <Text fontSize={responsive('1.125em', '2em')} fontWeight="bold">歡迎來到 RE:LAB 的資訊設計靈感任意門</Text>
            <Text mt="1em" fontSize={responsive('0.75em', '1.25em')} fontWeight={500}>
              只要有屬於靈感的任意門，就能幫助創意連結到所有地方，<br/>
              即使不能出國也能與全世界對話！<br/>
              {isAu && (
                <>
                  ＊此版本為升級版，請勿任意外流連結＊<br />
                  ＊專屬功能：新增工具、素材區塊，幫助你在進行資訊設計時更有效率＊
                </>
              )}
            </Text>
            <Media greaterThanOrEqual='md'>
              <Text mt={'1.625rem'} fontWeight="bold" fontSize={'1.25em'}>
                按下畫面下方的區塊按鈕，找到你正在苦惱的靈感發想階段，任意門就會帶你到你想去的地方！
              </Text>
            </Media>
            <Media lessThan='md'>
              <Text mt={'1em'} fontWeight="bold" fontSize={'0.75em'}>
                按下畫面右上角的選單，找到你正在苦惱的靈感發想階段，任意門就會帶你到你想去的地方！
              </Text>
            </Media>
          </Box>
        </Container>
      </Box>
      <Center px="1.5em" bg="white" flexWrap={'wrap'} pos={responsive('absolute', 'sticky')} opacity={responsive(0, 1)} top={responsive(0, "4em")} zIndex={responsive(-1, 5)}>
        {sections.map(({ name, key }) => (
          <Button
            cursor={'pointer'}
            as={Link}
            to={key}
            smooth
            spy
            hashSpy
            py="0.875rem"
            offset={key === 'reference' ? -120 : -70}
            variant={'transparent'}
            bg={tag === key ? 'black' : 'transparent'}
            _hover={{
              bg: 'tagButtonBg'
            }}
            color={tag === key ? 'white' : "black"}
            onClick={() => {
              trackNavClick(key)
            }}
            onSetActive={() => setTag(key)}
            borderRadius="0"
            key={key}
          >{name}</Button>
        ))}
      </Center>
      <Box
        color="white"
        bgGradient="linear(to-b, #9C9C9C 0%, #000000 61.2%)"
        pb={responsive('5em', '3.75em')}
      >
        {sections.map(({ Comp, key }) => (
          <Element name={key} id={key} key={key}>
            <Comp />
          </Element>
        ))}
      </Box>
      <Footer />
    </Box>
  )
}

export default IndexPage
