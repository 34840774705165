import React from 'react'
import { Box, Center, Spinner, Text } from '@chakra-ui/react'
import { Media } from 'contexts/responsive'
import SocialSwiper from 'components/SocialSwiper'
import ensureFisrestore from 'contexts/firebase/ensureFisrestore'
import useFirestoreInvPosts from 'hooks/useFirestoreInvPosts'

const text = '每週皆會定期更新 #資料視覺化 #懶人包 相關熱門貼文，\n若眼尖的你有發現不相關的貼文，點擊卡片右上角的 icon 即可幫所有人隱藏不相關的貼文！'

const InvFbPosts = (props) => {
  const { data } = useFirestoreInvPosts('fb')
  const { data: goodData } = useFirestoreInvPosts('fb1')
  // const { data: user } = useFirestoreUser('users', )
  // console.log(data)
  // const zippedData = useMemo(
  //   () =>
  //     goodData &&
  //     data &&
  //     zip(
  //       goodData.map((d) => ({
  //         ...d,
  //         isGood: true,
  //       })),
  //       data
  //     ),
  //   [data, goodData]
  // )
  if (!data || !goodData)
    return (
      <Center>
        <Spinner />
      </Center>
    )
    // console.log(goodData)
  return (
    <>
      <Media greaterThanOrEqual="md">
        <SocialSwiper data={goodData} fb good loop {...props} />
        <Text mt={'1.5em'} mb="2em" fontSize={'1em'} letterSpacing="0.08em" whiteSpace={'pre-wrap'}>
          {text}
        </Text>
        <SocialSwiper data={data} fb loop {...props} />
      </Media>
      <Media lessThan="md">
        <SocialSwiper data={goodData} good fb loop isAdmin={props.isAdmin} />
        <Text mt="3.25rem" mb="2.5em" fontSize={'0.75em'} letterSpacing="0.08em" whiteSpace={'pre-wrap'}>
          {text}
        </Text>
        <SocialSwiper data={data} fb loop isAdmin={props.isAdmin} />
      </Media>
    </>
  )
}

export default ensureFisrestore(InvFbPosts)
