import { useFirestoreCollectionData } from 'reactfire'
import useFirestore from "./useFirestore"

const useFirestorePosts = (name, size = 20) => {
  const { collection, orderBy, query, firestore, limit, where } = useFirestore()
  const fbRef = collection(firestore, name)
  return useFirestoreCollectionData(query(fbRef, where("ts", "!=", 0), orderBy('ts', 'desc'), limit(size)), { idField: 'id' })
}

export default useFirestorePosts
