import { Box, Container, Flex, Text } from '@chakra-ui/react'
import { responsive } from 'contexts/responsive'
import React from 'react'

const Section = ({ title, desc, children, noborder, ...props }) => {
  return (
    <Container pt={responsive('3.25em', '4em')}>
      <Box pt={!noborder && responsive('1.625em', '3.125em')} borderTop={!noborder && "2px solid"} {...props}>
        <Text
          fontSize={responsive('1.25em', '2.5em')}
          letterSpacing={responsive('0.18em', '0.2em')}
          fontWeight="bold"
          lineHeight={1.45}
        >{title}</Text>
        <Text
          mt={responsive('0.625em', '0.75rem')}
          fontSize={responsive('0.75em', '1.125em')}
          letterSpacing="0.08em"
          fontWeight={responsive(500, "bold")}
          whiteSpace={'pre-wrap'}
        >
          {desc}
        </Text>
        {children}
      </Box>
    </Container>
  )
}

export default Section
