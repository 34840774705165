import React from 'react'
import WebReferModule from 'components/WebReferModule'
import Section from 'components/Section'

const tools = [
  {
    title: '圖表產生器',
    text: '線上免費就能產出有設計感的圖表，用這些工具要做得醜也很難！',
    web: [
      {
        name: "Flourish",
        trackerName: 'tool_graph_flourish',
        desc: "同時是圖表產生器，也是簡報工具！內建多種動態圖表，快速做出會動的視覺化",
        href: "https://flourish.studio/features/"
      },
      {
        name: "PlotDB",
        trackerName: 'tool_graph_plotdb',
        desc: "台灣人開發的圖表工具！可製作多種圖表\r\n可以簡單快速做出台灣郷鎮地圖的視覺化",
        href: "https://plotdb.com/"
      },
      {
        name: "RAWGraphs",
        trackerName: 'tool_graph_rawgraphs',
        desc: "圖表產生器的先驅，非常適合用來做骨架\r\n可編輯的自由度相當高",
        href: "https://rawgraphs.io/"
      },
    ]
  },
  {
    title: '圖表圖鑑',
    text: '想不到要用哪種圖表時，可以幫助你視覺化思考的靈感圖鑑',
    web: [
      {
        name: "The Data Visualization Catalogue",
        trackerName: 'tool_handbook_thedatavisualizationcatalogue',
        desc: "收錄60種圖表的百科全書，詳細介紹每個圖表的功能、並提供對應製作工具",
        href: "https://datavizcatalogue.com/"
      },
      {
        name: "Data Viz Project",
        trackerName: 'tool_handbook_datavizproject',
        desc: "收錄超過100種圖表，可以依照功能、形狀、資料格式分類。每個圖表都附至少5個參考案例",
        href: "https://datavizproject.com/"
      },
      {
        name: "The Visualization Universe",
        trackerName: 'tool_handbook_theVisualizationuniverse',
        desc: "收錄圖表、工具、好書的大平台，可以依照搜尋熱度與成長排序找到最熱門的圖表",
        href: "https://visualizationuniverse.com/"
      },
    ]
  },
  {
    title: '製作小工具',
    text: '製作圖表的過程中，可以幫助你省時省力的小工具',
    web: [
      {
        name: "PDF Table Extractor",
        trackerName: 'tool_other_pdftableextractor',
        desc: "把PDF文件中的表格輕鬆取出，省下慢慢複製的痛苦時間",
        href: "http://ronnywang.github.io/pdf-table-extractor/"
      },
      {
        name: "remove.bg",
        trackerName: 'tool_other_removebg',
        desc: "不需要PS也能一鍵完成簡易去背，省下照片處理的時間",
        href: "https://www.remove.bg/zh"
      },
      {
        name: "Canva",
        trackerName: 'tool_other_canva',
        desc: "不需要繪圖軟體也能在線上完成美編排版，內建有素材庫並可以和Flourish直接串接",
        href: "https://www.canva.com/zh_tw/graphs/"
      },
    ]
  },
  {
    title: '色票工具',
    text: '海量的配色靈感，讓妳找到最符合作品故事的色彩組合',
    web: [
      {
        name: "Adobe Color",
        trackerName: 'tool_color_adobecolor',
        desc: "Adobe旗下的在線平台，你可以用關鍵字搜尋各種配色，還可以檢查顏色對比率、WCAG。",
        href: "https://color.adobe.com/zh/"
      },
      {
        name: "Coolors",
        trackerName: 'tool_color_coolors',
        desc: "可隨機生成調色盤，也可以根據你上傳的照片生成專屬配色",
        href: "https://coolors.co"
      },
      {
        name: "NIPPON COLORS",
        trackerName: 'tool_color_nipponcolors',
        desc: "日本傳統配色網站，適合尋找日系和風或素雅風格的配色靈感",
        href: "https://nipponcolors.com"
      },
    ]
  },
  {
    title: '色盲檢測工具',
    text: '運用色盲檢測工具，讓視覺障礙使用者也能很好的理解資訊，提升網頁的易用性',
    web: [
      {
        name: "who can use",
        trackerName: 'tool_colortest_who can use',
        desc: "設定前景、背景色、文字大小和樣式，網站會模擬視覺障礙使用者在瀏覽該配色組合時可能看到的樣貌，並提供評級",
        href: "https://whocanuse.com/"
      },
      {
        name: "Datawrapper",
        trackerName: 'tool_colortest_datawrapper',
        desc: "輸入數值後可以自動生成圖表，並判斷目前的圖表配色是否為色盲友善配色",
        href: "https://www.datawrapper.de/"
      },
      {
        name: "Coblis",
        trackerName: 'tool_colortest_coblis',
        desc: "上傳照片後，可以模擬出色盲影像，了解圖片色彩對視覺障礙者的可辨識程度",
        href: "https://www.color-blindness.com/coblis-color-blindness-simulator/"
      },
      {
        name: "Adobe Color",
        trackerName: 'tool_colortest_ddobecolor',
        desc: "網站可以根據三種不同類型的色盲視角檢查色彩，並標記出哪些色彩間會產生衝突，讓你更快速地選擇色彩",
        href: "https://color.adobe.com/zh/create/color-accessibility"
      },
    ]
  },
]

const Tool = () => {
  return (
    <Section
      title="用工具把靈感具象化"
      desc={'即將開始製作，卻對設計苦手嗎？\n參考這些開源的工具，並搭配色票、素材等設計資源，你也能完成一個好的作品！'}
    >
      <WebReferModule data={tools} />
    </Section>
  )
}

export default Tool
