const firebaseConfig = {
  apiKey: "AIzaSyCGHDM7Lt0kbdX4T0YY3vZbt7-YqIAFKBs",
  authDomain: "re-dokodemo-door.firebaseapp.com",
  projectId: "re-dokodemo-door",
  storageBucket: "re-dokodemo-door.appspot.com",
  messagingSenderId: "937646380014",
  appId: "1:937646380014:web:c66e5d75050d4da61074ff",
  measurementId: "G-EVKTH8VV8P"
};

export default firebaseConfig;
