import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useHover } from 'react-use';

import { responsive } from 'contexts/responsive'
import Link from './Link';
import Button from './Button';

const Card = ({ d }) => {
  const element = (hovered) =>
  <Box as={Link} href={d.href} width={responsive('100%', '50%', null, 1 / 4)}>
    <Button trackerName={d.trackerName} variant="link">
      <Box
        mx="1em"
        pos="relative"
        borderRadius={'1em'}
        overflow="hidden"
        mt={responsive('1.75em', '2em')}
        pt={responsive('0.5em', 0)}
        pb={responsive('0.875em', 0)}
      >
        <Flex position={responsive('absolute', 'static')} top="0" right="0" left="0" bottom="0">
          {d.bg.map(({color, width}, key) => (
            <Box bg={color} pt={responsive(0, '100%')} width={width || '100%'} key={key} />
          ))}
        </Flex>
        <Box pos={'absolute'} bottom="0" right="0" left="0" top="0" bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.69) 100%)" />
        <Box position={responsive('relative', 'static')} px="1em">
          <Box pos={responsive('static', 'absolute')} left="1em" right="1em" top={hovered ? '12.5%' : '57.5%'} transition="top 0.75s" textAlign={'justify'}>
            <Text fontSize={responsive('1em', '1.5em')} fontWeight="bold">{d.name}</Text>
            <Box fontSize={responsive('0.75em', '1em')} mt={responsive('0.5rem', hovered ? '0.75em' : 0)} opacity={responsive(1, hovered ? 1 : 0)} transition="all 1.25s">
              {d.text}
            </Box>
          </Box>
          <Flex pos={responsive('static', 'absolute')} left="1em" right="1em" bottom="1.25em" flexWrap={'wrap'} mt={responsive('0.625rem', 0)}>
            {d.tags.map(tag => (
              <Text mr="0.5rem" fontSize={responsive('0.75em', '0.875em')} key={tag}>#{tag}</Text>
            ))}
          </Flex>
        </Box>
      </Box>
    </Button>
  </Box>
  const [hoverable] = useHover(element);
  return (
    hoverable
  )
}

export default Card
