import React from 'react'
import { Box, Circle } from '@chakra-ui/react'
import { useHover } from 'react-use';
import Link from 'components/Link';


const FooterCircle = ({ d }) => {
  const element = (hovered) => (
    <Circle mx="auto" pos="relative" size="144px" border={'1px solid black'} bg={hovered ? 'black' : 'white'} overflow={'hidden'}>
      <Box width="86%" pos="absolute" top="50%" left="50%" transform={'translate(-50%, -50%)'} opacity={hovered ? 0 : 1}>
        {d.image}
      </Box>
      <Box width="86%" pos="absolute" top="50%" left="50%" transform={'translate(-50%, -50%)'} opacity={hovered ? 1 : 0}>
        {d.hoverImage}
      </Box>
    </Circle>
  )
  const [hoverable] = useHover(element);
  return (
    hoverable
  )
}

export default FooterCircle
