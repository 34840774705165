import React from 'react'
import useFirestore from 'hooks/useFirestore'
import ErrorBoundary from 'components/ErrorBoundary'

const ensureFisrestore = (SubComp, fallback = null) => props => {
  const firestore = useFirestore()
  return (
    <ErrorBoundary>
      {firestore ? <SubComp {...props} /> : fallback}
    </ErrorBoundary>
  )
}

export default ensureFisrestore
