import { AspectRatio, Box, Skeleton } from '@chakra-ui/react'
import React from 'react'
import { useFirestoreCollectionData } from 'reactfire'
import ensureFisrestore from 'contexts/firebase/ensureFisrestore'
import useFirestore from 'hooks/useFirestore'
import BackgroundImage from 'components/BackgroundImage'

const ratio = 250 / 188
const PinBoardCover = ({ id }) => {
  const { firestore, collection, query, where, orderBy, limit } = useFirestore()
  const pinsRef = collection(firestore, 'pins')
  const pinsQurey = query(
    pinsRef,
    where('board_id', '==', id),
    orderBy('created_at', 'desc'),
    limit(3)
  )
  const { data } = useFirestoreCollectionData(pinsQurey)
  // console.log(data)
  return (
    <AspectRatio ratio={ratio}>
      {data ? (
        <div>
          {[...data].reverse().map((pin, i) => (
            <Box
              key={i}
              pos="absolute"
              w="full"
              right={0 + i * 4}
              top="0"
              borderRadius={'1em'}
              overflow="hidden"
            >
              <BackgroundImage
                ratio={25 / 21}
                src={pin?.media?.images?.['400x300']?.url}
              />
            </Box>
          ))}
        </div>
      ) : (
        <Skeleton />
      )}
    </AspectRatio>
  )
}

export default ensureFisrestore(
  PinBoardCover,
  <AspectRatio ratio={ratio}>
    <div />
  </AspectRatio>
)
