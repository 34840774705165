import { theme, extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools"
import { responsive } from "contexts/responsive";
import breakpoints from "contexts/responsive/breakpoints";
import get from "lodash/get";

const font = 'Noto Sans TC, Arial, "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif';
const fonts = {
  heading: font,
  body: font,
  mono: "Menlo, monospace",
  inter: "Inter, sans-serif"
}

const colorScheme = 'blue';

const overrides = {
  fonts,
  colors: {
    ...theme.colors,
    text: get(theme.colors, 'black'),
    tagColor: '#FDC929',
    arrowFb: '#3D77EB',
    arrowIg: '#DE8B80',
    arrowTw: '#4FA6F6',
    reportGray: '#858585',
    dotGray: '#C4C4C4',
    twBlue: '#75AFEB',
    borderGray: '#E5E5E5',
    fbBlue: '#3272DF',
    buttonTextGray: '#535353',
    seeMoreGray: '#9C9C9C',
    tagButtonBg: '#D9D9D9',
    menuBg: '#EDEDED',
    fbActive: '#376BC3',
    igActive: '#BB6F65',
    twActive: '#4881BA',
    scrollIcon: '#FC7C34',
    au: '#9B8660'
  },
  breakpoints: createBreakpoints(breakpoints),
  sizes: {
    header: '4em',
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme,
      },
      variants: {
        transparent: {
          bg: 'transparent',
          px: '1em',
          py: '0.25rem',
          fontSize: responsive('1em', '1.125em'),
          // borderRadius: 'full',
          fontWeight: 500,
          letterSpacing: '0.08em',
          minW: 'auto',
          height: 'auto',
          _active: { bg: 'transparent'}
        },
        solid: {
          bg: 'linear-gradient(145.97deg, rgba(229, 229, 229, 0.616) 32.46%, rgba(229, 229, 229, 0.168) 100.63%)',
          borderRadius: '12px',
          color: 'white',
          py: '0.875rem',
          height: 'auto',
          _hover: {
            color: '#535353',
            bg: 'white'
          },
          _active : {
            color: '#535353',
            bg: 'white'
          }
        },
        link: {
          _hover: {},
          _active: {},
          _focus: {},
          color: 'white',
          bg: 'transparent',
          display: "block",
          whiteSpace: 'pre-wrap',
          width: '100%'
        }
      }
    },
    Container: {
      baseStyle: {
        maxW: responsive('32.5em', '72.125em'),
        px: responsive('1.25em', '2em')
      },
    },
  },
  colorScheme,
}

const customTheme = extendTheme(overrides)

export default customTheme
