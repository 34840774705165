import { useCallback } from 'react'

const useTrackClick = (namespace = '') => useCallback((btnName) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'click_button', {
      button_name: `${namespace ? `${namespace}.` : ''}${btnName}`,
    })
  } else {
    console.log('track', btnName, 'click')
  }
}, [namespace])

export default useTrackClick
